/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Cormorant+Garamond:300,400,500,600,700|Poppins:300,400,500,600,700,800,900|Abril+Fatface&display=swap");

/*-- Common Style --*/

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  background-color: $white;
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: $poppins;
    
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-color;
  // font-family:Helvetica !important; 
  font-weight: 400;
  margin-top: 0;
}

p,
p3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: $description-color;
  margin-bottom: 15px;
  // font-family:Helvetica !important; 
}


h1 {
  font-size: 36px;
  line-height: 42px;
}

h2 {
  font-size: 30px;
  line-height: 36px;
}

h3 {
  font-size: 24px;
  line-height: 30px;
}

h4 {
  font-size: 18px;
  line-height: 24px;
}

h5 {
  font-size: 14px;
  line-height: 18px;
}

h6 {
  font-size: 14px;
  line-height: 14px;
  font-style: bold;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  // color: #000;
}

a,
button,
img,
input,
span {
  transition: all 0.3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  &.share{

    list-style: outside none none !important;
    li::before {
      content: '';
      display: none !important;
      height: 18px;
      width: 18px;
      background-image: url('https://svgshare.com/i/e4v.svg');
      background-repeat: no-repeat;
      // padding: 6px 0 6px 4px;
      margin-inline-end: 5px;
    }
  
  }
  margin: 0;
  padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/

.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img  {
  background-position: center center;
  background-size: cover;
  object-fit: cover;
  // filter: brightness(.4);
  h3{
    @media only screen and (max-width: 500px) {
      font-size: 18px !important;
     }
  }
  h1{
      @media only screen and (max-width: 500px) {
        font-size: 20px !important;
        
       }  
  }
  p{
    @media only screen and (max-width: 500px) {
      font-size: 15px !important;   
     }  
  }

  p2{
    color: white;
    font-size: 30px !important; 
    @media only screen and (max-width: 500px) {
      font-size: 15px !important;   
     }  
  }
}

.filter{
  background-position: center center;
  background-size: cover;
  object-fit: cover;
  // filter: brightness(.4);
  height: 100%;
  z-index: -21;
  width: 100%;
  position: absolute;
  
  @media only screen and (max-width: 500px) {
    height: 100%;
    // width: 450px;
    // background: transparent linear-gradient(180deg, #00000000 0%, #03231440 58%, #054426 100%) 0% 0% no-repeat padding-box !important;
    opacity: 1;
   }
}

.grai{
  // background: transparent linear-gradient(180deg, #00000000 0%, #03231440 58%, #054426 100%) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  @media #{$xs-layout} {
    // background: transparent linear-gradient(180deg, #00000000 0%, #03231440 58%, #054426 100%) 0% 0% no-repeat padding-box !important;
    opacity: 1;
  }
}
.testf{
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 7;
}

.z-index-8{
  z-index: 8;
}
.z-index{
  z-index: 2;
  // color: red;
 
  &:hover{
    // color: red;
  }
}
.border-bottom-1 {
  border-bottom: 1px solid #d5d5d5;
}

a:hover {
  color: $theme-color;
}

.btn-outline-light{
  // border-color: $heading-color !important;
  // color: $heading-color !important;
  background-color: $heading-color!important;
  &:hover{
    background-color: $heading-color!important;
    color: white!important;
  }
}

/*************************
  Basic margin padding
*************************/

.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
         Margin top
*************************/

.mt-0 {
  margin-bottom: 0;
}

.mt-10 {
  margin-top: 10px;
}
.mt-9r {
  margin-top: 9rem !important;
}
.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
  @media #{$xs-layout} {
    margin-top: 60px;
  }
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mt-250 {
  margin-top: 250px;
}

/*************************
      Margin right
*************************/

.mr-0 {
  margin-right: 0;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-20-im {
  margin-right: 20px !important;
}


.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-70 {
  margin-right: 70px;
}
.mrr-70 {
  margin-right: 77px;
  @media screen and (min-width: 1900px) {
    margin-right: 330px;
  }
}
.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/*************************
      Margin bottom
*************************/

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.my-15{
  @media screen and (min-width: 1800px) {
    margin-top: 15rem !important;
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;

}

.mb-50 {
  margin-bottom: 50px;
}

.mb-50-170lg {
  margin-bottom: 50px;
  @media screen and (min-width: 1800px) {
    margin-bottom: 190px;
  }
}

.mb-55 {
  margin-bottom: 55px;
}
.ml-36 {
  margin-left: 37px;
}

.ml-55 {
  margin-left: 455px;
}

.mb-60 {
  margin-bottom: 60px;
}
.mb-40rem {
  @media screen and (min-width: 1800px) {
  margin-bottom: 40rem;
  }
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
  @media #{$xs-layout} {
    margin-bottom: 60px;
  }
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
  @media #{$xs-layout} {
    margin-bottom: 50px;
  }
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}
.mb-170 {
  @media screen and (min-width: 1800px) {
  margin-bottom: 185px;
  }
}

.mb-520{
  @media screen and (min-width: 1800px) {
    margin-bottom: 520px;
    }
}

/*************************
        Margin left
*************************/

.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-20-im {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-190{
  margin-left: 130px;
  @media screen and (min-width: 1900px) {
    margin-left: 350px;
  }
}

.ml-160 {
  margin-right: 120px;
  @media #{$xs-layout} {
    margin-right: 20px;
  }
  @media #{$lg-layout} {
    margin-left: 0px;
  }
}

.ml-ntv5 {
  margin-left: -5px;
}

/*************************
        Padding top
*************************/

.pt-0 {
  padding-top: 0;
}

.pt-n20 {
  margin-top: -20;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
  @media #{$xs-layout} {
    padding-top: 60px;
  }
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
  @media #{$xs-layout} {
    padding-top: 60px;
  }
}

.pt-95 {
  padding-top: 95px;
  @media #{$xs-layout} {
    padding-top: 55px;
  }
}

.pt-90 {
  padding-top: 90px;
  @media #{$xs-layout} {
    padding-top: 50px;
  }
}

.pt-100 {
  padding-top: 100px;
  @media #{$xs-layout} {
    padding-top: 60px;
  }
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
  @media #{$xs-layout} {
    padding-top: 40px;
  }
}

.pt-115 {
  padding-top: 115px;
  @media #{$xs-layout} {
    padding-top: 60px;
  }
}

.pt-120 {
  padding-top: 120px;
  @media #{$xs-layout} {
    padding-top: 50px;
  }
}

.pt-125 {
  padding-top: 125px;
  @media #{$xs-layout} {
    padding-top: 55px;
  }
  @media #{$md-layout} {
    padding-top: 75px;
  }
}

.pt-128 {
  padding-top: 128px;
}

.pt-130 {
  padding-top: 130px;
  @media #{$xs-layout} {
    padding-top: 60px;
  }
  @media #{$md-layout} {
    padding-top: 80px;
  }
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;
  @media #{$xs-layout} {
    padding-top: 60px;
  }
}

.pt-205 {
  padding-top: 205px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-270 {
  padding-top: 270px;
  @media #{$md-layout} {
    padding-top: 170px;
  }
  @media #{$xs-layout} {
    padding-top: 100px;
  }
}

.pt-240 {
  padding-top: 240px;
}

.pt-365 {
  padding-top: 365px;
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

/*************************
        Padding right
*************************/

.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: ($spacer * .55) !important;

}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-270 {
  padding-right: 270px;
}

/*************************
        Padding bottom
*************************/

.pb-0 {
  padding-bottom: 0;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
  @media #{$xs-layout} {
    padding-bottom: 15px;
  }
}

.pb-60 {
  padding-bottom: 60px;
  @media #{$xs-layout} {
    padding-bottom: 20px;
  }
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
  @media #{$xs-layout} {
    padding-bottom: 30px;
  }
}

.pb-80 {
  padding-bottom: 80px;
  @media #{$xs-layout} {
    padding-bottom: 40px;
  }
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
  @media #{$xs-layout} {
    padding-bottom: 50px;
  }
}

.pb-95 {
  padding-bottom: 95px;
  @media #{$xs-layout} {
    padding-bottom: 55px;
  }
}

.pb-100 {
  padding-bottom: 100px;
  @media #{$xs-layout} {
    padding-bottom: 30px;
  }
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
  @media #{$xs-layout} {
    padding-bottom: 60px;
  }
}

.pb-120 {
  padding-bottom: 120px;
  @media #{$xs-layout} {
    padding-bottom: 50px;
  }
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
  @media #{$xs-layout} {
    padding-bottom: 60px;
  }
  @media #{$md-layout} {
    padding-bottom: 80px;
  }
}

.pb-140 {
  padding-bottom: 140px;
  @media #{$xs-layout} {
    padding-bottom: 60px;
  }
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-270 {
  padding-bottom: 270px;
  @media #{$md-layout} {
    padding-bottom: 170px;
  }
  @media #{$xs-layout} {
    padding-bottom: 100px;
  }
}

.pb-290 {
  padding-bottom: 290px;
}

/*************************
        Padding left
*************************/

.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-190 {
  padding-left: 190px;
}

/***************************
    Page section padding 
****************************/

.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

/***************************
    Page section margin 
****************************/

.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

.mb--10 {
  margin-bottom: -10px;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/

input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: $web-disable;
  width: 100%;
}

select {
  width: 100%;
  background: transparent;
  border: 0 solid #eceff8;
  box-shadow: none;
  font-size: 14px;
  color: $web-disable;
}

option {
  background: #fff;
  border: 0 solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: transparent;
}

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px;
}

textarea:focus {
  background: transparent;
  border: 2px solid #4fc1f0;
  outline: none;
}

.input-group.divcenter.input-group .form-control {
  padding-left: 0;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  @media #{$xl-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }
  @media #{$lg-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }
  @media #{$md-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }
  @media #{$xs-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.custom-row-2 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  & div[class^="col-"] {
    padding-left: 5px;
    padding-right: 5px;
    @media #{$md-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media #{$xs-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media #{$xl-layout} {
    margin-right: -5px;
    margin-left: -5px;
  }
  @media #{$lg-layout} {
    margin-right: -5px;
    margin-left: -5px;
  }
  @media #{$md-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }
  @media #{$xs-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.custom-row-3 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -13.6px;
  margin-left: -13.6px;
  & div[class^="col-"] {
    padding-left: 13.6px;
    padding-right: 13.6px;
    @media #{$xs-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media #{$xl-layout} {
    margin-right: -13.6px;
    margin-left: -13.6px;
  }
  @media #{$lg-layout} {
    margin-right: -13.6px;
    margin-left: -13.6px;
  }
  @media #{$md-layout} {
    margin-right: -13.6px;
    margin-left: -13.6px;
  }
  @media #{$xs-layout} {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
  @media #{$xl-layout} {
    padding-right: 15px;
    padding-left: 15px;
  }
  @media #{$lg-layout} {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  @media #{$md-layout} {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media #{$xs-layout} {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media #{$sm-layout} {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.mobile-menu-area {
  display: none;
  @media #{$md-layout} {
    display: block;
  }
  @media #{$xs-layout} {
    display: block;
  }
  @media #{$sm-layout} {
    display: block;
  }
  &.white-mobile-menu {
    a.meanmenu-reveal {
      color: #fff;
      border: 1px solid #fff;
      span {
        background-color: #fff;
      }
    }
  }
}

.stick {
  .mobile-menu-area {
    &.white-mobile-menu {
      a.meanmenu-reveal {
        color: $web-disable;
        border: 1px solid $web-disable;
        span {
          background-color: $web-disable;
        }
      }
    }
  }
}

.mean-container a.meanmenu-reveal {
  top: -30px;
}

#mobile-menu-active {
  display: none;
}

.mobile-menu .mean-nav ul.menu-overflow {
  height: 215px;
  margin-top: 10;
  overflow-y: auto;
}

.mobile-menu.mean-container .mean-nav ul {
  margin: 14px 0 0;
}

.px-50 {
  padding-left: ($spacer * .50) !important;
  padding-right: ($spacer * .50) !important;
}

.p-30 {
  width: auto;
  height: 300px;
  right: 80px;
  @media #{$xs-layout} {
    width: auto;
    height: 300px;
    right: 80px;
  }
}

.p-31 {
  height: 300px;
  top: 50px;
  @media #{$xs-layout} {
    height: 0px;
    top: -40px;
    right: -40px;
    width: 20px;
  }
}

.p-32 {

  @media #{$xs-layout} {
    height: 300px;
  }
}

/*-- 
    Scroll Up 
-----------------------------------------*/

.scroll-top {
  position: fixed;
  width: 50px;
  height: 50px;
  border: none;
  background-color: $web-yellow-color;
  color: #fff;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
  visibility: hidden;
  opacity: 0;
  &.show {
    visibility: visible;
    opacity: 1;
  }
  @media #{$xs-layout} {
    width: 40px;
    height: 40px;
  }
  & i {
    display: block;
    line-height: 50px;
    font-size: 22px;
    @media #{$xs-layout} {
      line-height: 40px;
      font-size: 16px;
    }
  }
  &:hover {
    & i {
      animation-name: fadeInUp;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
    }
  }
}

.social-media-floating-icons-box {
  
  background: lightgrey;
  width: 50px;
  position: fixed;
  top: 40%;
  right: 0px;
  z-index: 999;
  @media only screen and (max-width: 500px) {
    
     display: none;
  
    z-index: 99;
    }
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  .social-icon-btn {
    width: 50px;
    height: 50px;
    border:none;
    color : $web-green-color;
    display: block;
    &:hover{
      color: $web-yellow-color;
    }
  }
  .social-fb{
    border-top-left-radius: 10px;
  }
  .social-youtube{
    border-bottom-left-radius: 10px;
  }
}


.top-40{
  position: relative;
  bottom: 50px;
}
.absolute-wrapper {
  position: absolute !important;
  bottom: 0;
  right: 0;
  z-index: 3;
  align-self: center;
  left: 20px;
  @media #{$xs-layout} {
    bottom: -50px;
  }

}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.reco {

  left: 350px;
  @media #{$sm-layout} {
    left: 120px;
  }
  @media #{$md-layout} {
    left: 120px;
  }
  @media #{$lg-layout} {
    left: 270px;
  }
  @media #{$xl-layout} {
    left: 320px;
  }
  @media screen and (min-width: 1900px) {
    left: 480px;
  }

}

.reco {

  left: 350px;
  @media #{$sm-layout} {
    left: 120px;
  }
  @media #{$md-layout} {
    left: 120px;
  }
  @media #{$lg-layout} {
    left: 270px;
  }
  @media #{$xl-layout} {
    left: 320px;
  }
  @media #{$xs-layout} {
    left: 0px;
  }
  @media screen and (min-width: 1900px) {
    left: 480px;
  }
}

.nono-2 {
  bottom: 130px;
  @media #{$xs-layout} {
    position: relative;
    bottom: 98px;
  }
  
}

.height-100 {
  height: 100vh;
}

.navbar {
  outline: 1px solid #ddd;
}

.nav-item {
  margin-right: 15px;
}

/*--
    - Background Color
------------------------------------------*/


.bg-theme-green{
  background-color: $web-green-color;
}


.bg-black {
  background-color: #000000;
}

.bg-white {
  background-color: #fff;
}

.bg-reco {
  background-color: #E8E6E6;
}



.btn-hover {
  a,
  button {
    position: relative;
    transition: all 0.5s ease-in-out 0s;
    &:hover {
      border: 1px solid $theme-color;
      color: #fff;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
      z-index: -1;
    }
    &::after {
      width: 0;
      left: auto;
      right: 0;
      background: $theme-color;
    }
    &:hover::after {
      width: 100%;
      left: 0;
      right: auto;
    }
  }
}

a.default-btn {
  font-size: 16px;
  color: #010101;
  display: inline-block;
  border: 1px solid $web-disable;
  text-transform: uppercase;
  line-height: 1;
  padding: 19px 50px 21px;
}

/* slider img bounce */

@-webkit-keyframes bounce-img {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce-img {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes snow {
  0% {
    background-position: 0 0, 0 0, 0 0;
  }
  100% {
    background-position: 500px 1000px, 400px 400px, 300px 300px;
  }
}



.padding-10-row-col {
  .row {
    margin-right: -5px;
    margin-left: -5px;
    div[class^="col-"] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.padding-20-row-col {
  .row {
    margin-right: -10px;
    margin-left: -10px;
    div[class^="col-"] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

[class*="ToastContainer"] {
  overflow: hidden !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (min-width: 1900px) {
  .container {
    max-width: 1700px !important;
  }
}


.item-empty-area {
  &__icon {
    i {
      font-size: 140px;
      line-height: 1;
      @media #{$xs-layout} {
        font-size: 100px;
      }
    }
  }

  &__text {
    font-size: 20px;
    a {
      margin-top: 30px;
      font-weight: 500;
      display: inline-block;
      background-color: #363f4d;
      color: $white;
      font-size: 14px;
      padding: 10px 30px;
      transition: 0.3s;
      &:hover {
        background-color: $theme-color;
        color: $white;
      }
    }
  }
}

.view-all-slider-button-view { 
  margin-top: 15vh;
  @media screen and (min-width: 1900px) {
    margin-top: 12vh;

  }
  &.landing{
    margin-top: 25vh;
    border: solid #2C5645;

  }
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  color: white !important;
  border: solid $web-yellow-color;
  border-width: 2px 2px;
  margin-inline-start: 25px;
  width: 120px;
  &:hover{
    background-color:$web-yellow-color ;
  }
}

.view-all-button{
  margin: auto;
  color: white !important;
  margin-top: 5px;
  margin-bottom: 5px;
  &.landing{
    p{
      color: #2C5645 ;
    }
  }
  p{
    color: white ;
  }
  a{
    &:hover{
      text-decoration: none;
    }
  }

}

.text-align-right{
  text-align: right;
}