
/*-------- 4. Support style  -------*/
.social{
  height: 400px;
  width: 10%;
}

.switchlang{
  // min-height: 15px !important;
  width: 5px;
}
.gold-shadow {
  content: "";

  
}

.gold-shadow::before {
  content: "";
  top: -21px;
  left: 40px;
  width: 95%;
  height: 90%;
  
  background-image: linear-gradient(
0deg, rgb(210 158 14 / 65%), rgb(210 158 14 / 65%)),url(https://i.ibb.co/c2Vc3Mb/shutterstock-1766512784.jpg);
  position: absolute;
  z-index: -999999;
}
.gold-shadow-sub {
  content: "";

  
}

.gold-shadow-2 {
  content: "";

  
}

.gold-shadow-2::before {
  content: "";
  top: -21px;
  left: 40px;
  width: 95%;
  height: 100%;
  
  background-image: linear-gradient(
0deg, rgb(210 158 14 / 65%), rgb(210 158 14 / 65%)),url(https://i.ibb.co/c2Vc3Mb/shutterstock-1766512784.jpg);
  position: absolute;
  z-index: -999999;
}
.gold-shadow-3 {
  content: "";

  
}

.gold-shadow-3::before {
  content: "";
  top: 129px;
    left: 797px;
    width: 16%;
    height: 22%;
  
  background-image: linear-gradient(
0deg, rgb(210 158 14 / 65%), rgb(210 158 14 / 65%)),url(https://i.ibb.co/c2Vc3Mb/shutterstock-1766512784.jpg);
  position: absolute;
  z-index: -999999;
}

.gold-shadow-sub {
  content: "";

  
}

.gold-shadow-sub::before {
  content: "";
  top: -21px;
  left: 40px;
  width: 95%;
  height: 70%;
  
  background-image: linear-gradient(
0deg, rgb(210 158 14 / 65%), rgb(210 158 14 / 65%)),url(https://i.ibb.co/c2Vc3Mb/shutterstock-1766512784.jpg);
  position: absolute;
  z-index: -999999;
}

.box-video{
  position: relative;
  width: 100%;
  // height: 80%;
  height: 90%;
  margin:0 auto 20px auto;
  cursor: pointer;
  overflow: hidden;
  // border:0.3px solid #fff;
  &.bord{
    box-shadow: 10px 10px 2px #D29E0E;
    -moz-box-shadow: 10px 10px 2px #D29E0E;
    -webkit-box-shadow: 10px 10px 2px #D29E0E;
    -khtml-box-shadow: 10px 10px 2px #D29E0E;
    
  }
}


 /* Set Cover aka Background-Image */
.box-video .bg-video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
}

 /* Add light shade to make play button visible*/
.bg-video::after{ 
	content:'';
	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  	background-color: rgba(0,0,0,0.1);
  	z-index: 3;
  	}


/* The Play-Button using CSS-Only */
.box-video .bt-play {
  position: absolute;
  top:50%;
  left:50%;
  margin:-30px 0 0 -30px;
  display: inline-block;
  width: 60px;
  height: 60px;
  background: rgba(255,255,255,0.1);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 50%;
  text-indent: -999em;
  cursor: pointer;
  z-index:2;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

/* The Play-Triangle */
.box-video .bt-play:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  height: 0;
  width: 0;
  margin: -12px 0 0 -6px;
  border: solid transparent;
  border-left-color: #000;
  border-width: 12px 20px;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.box-video:hover .bt-play {
  transform: scale(1.1);
}

/* When Class added the Cover gets hidden... */
.box-video.open .bg-video{
  visibility: hidden;
  opacity: 0;

  -webkit-transition: all .6s .8s;
  transition: all .6s .8s;  
}

/* and iframe shows up */
.box-video.open .video-container{
  opacity: 1;
  -webkit-transition: all .6s .8s;
  transition: all .6s .8s;
}

/* Giving the div ratio of 16:9 with padding */
.video-container {
	
  z-index: 1;
	
}

.video-container iframe {
	
}

.card-body{
  h1{
    // font-size: 36px !important;
    // letter-spacing: 0.02em !important;
    // line-height: 1.3em !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1; 
    -webkit-box-orient: vertical;
    @media only screen and (max-width: 500px) {
      margin-top: 20px !important;
      margin-bottom: 5px !important;
      // font-size: 14px !important;
      // letter-spacing: 0.01em !important;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2; 
      -webkit-box-orient: vertical;
     }
     @media only screen and (max-width: 1200px) {
      margin-top: 20px !important;
      margin-bottom: 5px !important;
      // font-size: 14px !important;
      // letter-spacing: 0.01em !important;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2; 
      -webkit-box-orient: vertical;
     }
  }
  p{
    line-height: 1.9em !important;
    // font-size: 16px !important;
    // letter-spacing: 0.05em !important;
    @media only screen and (max-width: 500px) {
      // font-size: 10px !important;
     }
  }
  .button {
    
    color: $web-green-color;
    width: 170px;
    height: 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 20px;
    border: 2px solid $web-green-color;
    background-color: white;
    // margin: 0px 100px;
    @media only screen and (max-width: 500px) {
      width: 100px;
    height: 30px;
    font-size: 10px;
     }
  }

  .button:hover {
    background-color: $web-green-color;
    color: white;
  }
}

.support-wrap {
  display: flex;
  .support-icon {
    flex: 0 0 50px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  .support-content {
    h5 {
      font-size: 18px;
      margin: 0 0 5px;
    }
    p {
      color: #666;
      margin: 0;
    }
  }
  &.support-2 {
    margin-left: 22px;
    @media #{$lg-layout} {
      margin-left: 0px;
    }
    @media #{$md-layout} {
      margin-left: 0px;
    }
    @media #{$xs-layout} {
      margin-left: 0px;
    }
    .support-icon {
      flex: 0 0 41px;
    }
  }
  &.support-3 {
    margin-left: 9px;
    @media #{$lg-layout} {
      margin-left: 0px;
    }
    @media #{$md-layout} {
      margin-left: 0px;
    }
    @media #{$xs-layout} {
      margin-left: 0px;
    }
    .support-icon {
      flex: 0 0 41px;
    }
  }
  &.support-4 {
    margin-left: 23px;
    @media #{$lg-layout} {
      margin-left: 0px;
    }
    @media #{$md-layout} {
      margin-left: 0px;
    }
    @media #{$xs-layout} {
      margin-left: 0px;
    }
    .support-icon {
      flex: 0 0 35px;
    }
  }
  &:hover .support-icon img {
    animation: 500ms ease-in-out 0s normal none 1 running tada;
  }
}
.vidoesm{
  top: 30px;
  @media #{$xs-layout} {
    position: relative;
    top: 10px;
    height: 250px !important;
    margin-top: 20px;
  }
  @media only screen and (max-width: 500px) {
    position: relative;
    top: 20px;
    height: 300px !important;
    margin-top: 0px;
  }
}
.slider-logo{
  @media only screen and (max-width: 500px) {
   width: 100px;
   margin-bottom: 20px;
   }
}
.text-mob{
  @media only screen and (max-width: 500px) {
    color: #000;
    font-size: 4px;
    }
}
/* support hm3 */
.support-wrap-2 {
  
  .support-content-2 {
    img{
      width:100% ;
      height:auto;
      object-fit: contain;
      // max-width: 200px;
      // max-height: 85px;
      @media #{$sm-layout} {
        width: 100%;
      }
      @media #{$xs-layout} {
        width: 100%;
      }
    }
    h5 {
      font-size: 18px;
      color: #494949;
      margin: 15px 0 15px;
      line-height: 24px;
    }
    p {
      font-size: 14px;
      color: #6a6a6a;
      margin: 0 auto;
      width: 80%;
      @media #{$md-layout} {
        width: 100%;
      }
    }
  }
  &:hover .support-content-2 img {
    // transform: scale(1.1);

  }
  &.support-shape {
    position: relative;
    &:before {
      position: absolute;
      right: -14px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #d5d4d4;
      height: 61px;
      width: 1px;
      content: "";

      @media #{$xs-layout} {
        display: none;
      }
    }
  }
  &.support-shape-2 {
    border: 4px solid #fff;
  }
  &.support-shape-3 {
    border: 4px solid #f6f6f6;
  }
  &.support-padding-2 {
    // padding: 36px 10px 30px;
    @media #{$lg-layout} {
      padding: 16px 5px 10px;
    }
    @media #{$xs-layout} {
      padding: 16px 6px 10px;
    }
  }
}

.max-height{
  max-height: 20px;
}

.max-width{
  width: 1075px!important;
}

.support-bg-color-1 {
  background-color: #ccfbe9;
}

.support-bg-color-2 {
  background-color: #f2fbcc;
}

.support-bg-color-3 {
  background-color: #ddfbcc;
}
.nopadding {
  margin: 10px !important;
justify-self: center;
align-self: center;
position: relative;
left: 90%;
text-align: start;
@media #{$md-layout} {
  bottom: 120px;
}
@media #{$lg-layout} {
  bottom: 80px;
  left: 80%;
}
@media #{$xs-layout} {
  bottom: 120px;
  left: 50%;

}
@media #{$sm-layout} {
  left: 60%;

}
@media #{$xl-layout} {

}
 @media screen and (min-width: 1900px) {
        left: 480px;
      }

}

.heho{
  font-size: 50px;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 0em;
    text-align: start;
}

.contentcenter{
  justify-content: center;
  text-align: start;
  align-self: center;
  justify-self: center;
  transform: translate(-50%,50%);
  left: 50%;
  
  // top: 50px;
  position: absolute;
  .h12 {
    color: #fff;
    background-color: #cfc0cf;
     margin: 4px 0 0;
    text-transform: uppercase;
    @media #{$xs-layout} {
      font-size: 15px;
    }
    @media #{$sm-layout} {
      font-size: 14px;
    }
  }

}

.support-wrap-3 {
  position: relative;
  overflow: hidden;
  display: flex;
  // opacity: .4;
 width: 200px;
 height: 190px;
 left: 50%;
//  top: 10px;
// padding: 20px;
background: rgba(255, 255, 255, 0.4);
transform: translate(-50%,50%);
@media #{$xs-layout} {
  width: 137px;
 height: 100px;
 top: 150px;
 
}
@media #{$lg-layout} {
  width: 150px;
 height: 150px;
 top: 100px;
 
}

@media #{$sm-layout} {
  width: 157px;
 height: 140px;
 top: 80px;
}


  .support-icon-2 {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .support-content-3 {
    z-index: 50;
    text-align: center;
    margin: -64px 0 0;
    z-index: 99;
      position: relative;
      word-wrap: break-word;
    img {
    }
    .details{
      padding-top: 30px;
      font-size: 14px;
    }
    p {
      // background: rgba(0, 151, 19, 0.3);
      // background: rgba(255,255,255,.6);
      margin: 0 0 5px;
      color: #FFFFFF;
      margin: 4px 0 0;
      font-size: 50px;
      text-transform: uppercase;
      font-weight: 500px;
      font-family: arial,ｍｓ ｐゴシック,ms pgothic,돋움,dotum,helvetica,sans-serif;
      z-index: 50;
      position: relative;
      word-wrap: break-word;

      @media #{$xs-layout} {
        font-size: 15px;
      }
      @media #{$sm-layout} {
        font-size: 14px;
      }
    }
    p2 {
      
      margin: 0 0 5px;
      color: #FFFFFF;
      letter-spacing: 0.1em;
      margin: 4px 0 0;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 20;
      white-space: nowrap;
     font-family: "Times New Roman", Times, serif;
      z-index: 50;
      position: relative;
      top: 20px;
      word-wrap: break-word;

      @media #{$xs-layout} {
        font-size: 10px;
      }
      @media #{$sm-layout} {
        font-size: 14px;
      }
    }
  }
  &:hover .support-icon-2 img {
    animation: 500ms ease-in-out 0s normal none 1 running tada;
  }
}
.text-limit{
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 7; /* number of lines to show */
           line-clamp: 7; 
   -webkit-box-orient: vertical;
  @media only screen and (max-width: 500px) {

  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 5; /* number of lines to show */
           line-clamp: 5; 
   -webkit-box-orient: vertical;
  }
  @media #{$xs-layout} {
    
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
          line-clamp: 5; 
  -webkit-box-orient: vertical;
  }
}
.text-limit-3{
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;
  @media only screen and (max-width: 500px) {

  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 5; /* number of lines to show */
           line-clamp: 5; 
   -webkit-box-orient: vertical;
  }
}
.text-limit-3-mob{
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;
  @media #{$xs-layout} {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
  }
  
}
.text-limit-2{
  overflow: hidden;
   text-overflow: ellipsis;
   
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1; 
   -webkit-box-orient: vertical;
  @media only screen and (max-width: 500px) {

  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1; 
   -webkit-box-orient: vertical;
  }
}
.text-limit-2-2{
  overflow: hidden;
   text-overflow: ellipsis;
   
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
  @media only screen and (max-width: 500px) {

  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
  }
}
.text-limit-2-3{
  overflow: hidden;
   text-overflow: ellipsis;
   
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;
  @media only screen and (max-width: 500px) {

  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
  }
}
.height-960{
  height: 910px;
  @media only screen and (max-width: 500px) {
    height: 820px;
   }
   @media (min-width: 1200px) {
      height: 960px;
  }
}
@media #{$xs-layout} {
  .support-area {
    &.pt-80 {
      padding-top: 30px;
    }
  }
}

.feature-icon-two-wrap {
  .col-md-4 {
    &:last-child {
      .support-wrap-2 {
        &.support-shape {
          &:last-child {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.postion-relative{
  position: relative;
  bottom: 20%;
}

.hm-20{
  .card-body{
    p{
      height: 20px;
      line-height: 1,2em !important;
    }
  }
  // height: 35px;
  
}

.fotext{
  letter-spacing: 0px;
  
  p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
letter-spacing: 0px;
font-size: 13px;  
line-height: 10px;}
}

.inline-block{
  display: inline-block;
  p{
    letter-spacing: 0px;
    font-size: 13px;  
    line-height: 10px;
    }
}