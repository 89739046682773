
.social-section-title {
    display: inline-block;
    font-size: 36px !important;
    color: #000000 !important;
    font-weight: 600 !important;
}

.social-section-link {
    font-size: 20px;
    color: #363B40;
    &:hover{
        color: $web-yellow-color;
        text-decoration: none;
    }
}