.ticket1 {
    // width:8in;
    width:7in;
    height:2.7in;
    background-size:cover;
    background-repeat:no-repeat;
    position:relative;
    // margin-bottom: 0.2in;
    margin-bottom: 0.05in;
}

.ticket-img1 {
    max-width: 100%;
    height: auto;
    position: relative;
    /*display: inline-block;*/
}

#event-info1 {
    display:inline-block;
    position:absolute;
    // left:0.9in;
    left:0.8in;
    top:0.12in;
    width:4.7in;
}

.label1 {
    color:#768690;
    display:block;
    text-transform:uppercase;
}

.value1 {
    // display:block;
    color:#121212;
    text-transform:uppercase;
    overflow:hidden;
    font-size:16px;
    max-width: 180px;
}

.postion-absolute{
    position: absolute;
}

.padding-left-0{
    padding-left: 0px !important;
}

#title11 {
    height:0.4in;
}

#location1 {
    height:0.8in;
}

#stub-info1 {
    display:block;
    position:absolute;
    // top:0.06in;
    top:0.3in;
    // left:6in;
    left:5.1in;
    width:1.9in;
    text-align:center;
}

#purchased-on1 {
    display:inline-block;
    color:#fff;
    text-transform:uppercase;
    font-size:9px;
    text-align:center;
    width:100%;
    position:relative;
}

#qrcode11{
    position:relative;
    width: 70%;
    height: auto;
    margin-top: 0.3in;
    margin-left: -1.9in;
}

#ticket-num1 {
    display:block;
    text-transform:uppercase;
    text-align:center;
    width:100%;
    position:relative;
    top: 0;
    left: 0;
    font-weight:bold;
    font-size: 10px;
}

#attendee-info1 {
    text-align:left;
    font-size:10px;
    position:relative;
    top:0.18in;
    left: 0.2in;
    line-height: 1.6em;
}

#attendee-info .value1 {
    font-size:10px;
}


.ticket2 {
    width:3.2in;
    background-size:cover;
    background-repeat:no-repeat;
    position:relative;
    margin-bottom: 0.05in;
    
    .ticket-img2 {
        max-width: 100%;
        height: auto;
        position: relative;
        border: 2px solid #999c9e;
        border-radius: 5px;
    }

    .stub-info2 {
        display:block;
        position:absolute;
        top:0.3in;
        left:0.05in;
        width:2.6in;
        text-align:center;
    }
    .ticket-num2 {
        display:block;
        text-transform:uppercase;
        text-align:center;
        width:100%;
        position:relative;
        top: 0.5px;
        left: 0;
        font-weight:bold;
        font-size: 12px;
    }
    .event-info2 {
        display:inline-block;
        position:absolute;
        left: 0.1in;
        top: 40%;
        // width:4.7in;
        font-size: 14px;
    }
    .label1 {
        color:#768690;
        display:block;
        text-transform:uppercase;
    }
    .pic-block{
        display: block;
        position: absolute;
        width: 2in;
        bottom: 5px;
        left: 0.4in;
    }
}

.ticket-box-head {
    color: #ffffff; 
    background-color: #2C5243; 
    font-weight: bold;
}

.overlay-tick{
    // position: absolute;
    // width: 100%;
    // height: 100%;
    margin-left: -15px;
    opacity: .9;
    background-image: url(https://cdn.ticketingboxoffice.com/images/pattern1.jpg);
    background-repeat: repeat;
    // top: 0;
    // left: 0;
}


.desktop-invoice{
    margin-bottom: 30px;

    .desktop-invoice-box{
        border: 1px solid #9EA8AC !important;
        min-height: 180px !important;

        .flex-items-center{
            display: flex !important;
            align-items: center !important;
        }
        .content-center{
            justify-content: center !important;
        }
        .invoice-img{
            max-height: 300px;
            width: 100% !important; 
            // height: 100% !important; 
            object-fit: cover !important;
            border: 1px solid #9EA8AC !important;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 0 10px 0 rgba(0, 0, 0, 0.19);
        }
        .muted-text{
            color: #A8B1B5 !important;
            margin-bottom: 5px !important;
        }
        .bold-text{
            color: $web-green-bg  !important;
            margin-bottom: 5px !important;
            font-size: 22px !important;
            font-weight: 700 !important;
        }
        .fz-13{
            font-size: 13px !important;
        }
        .fz-17{
            font-size: 17px !important;
        }
        .invoice-download-box{
            background-color: $web-yellow-color;
        }
        .download-link{
            &:hover {
                text-decoration: none;
            }
        }
        .invoice-download{
            background-color: $web-yellow-color;
            color: #ffffff !important;

            .text-white{
                color: #ffffff !important;
            }
        }
    }

    .hr{
        width: 2px;
        height: 50%;
        background-color: #EBEDEE;
        position: absolute !important;
        top: 25% !important;
    }
    .hr-right{
        right: 0 !important;
    }
    .hr-left{
        left: 0 !important;
    }
    .right-border{
        height: 100% !important;
    }
}

.mobile-invoice{
    margin-top: 50px;

    .mobile-invoice-box{
        border: 1px solid #9EA8AC !important;
        min-height: 180px !important;

        .flex-items-center{
            display: flex !important;
            align-items: center !important;
        }
        .content-center{
            justify-content: center !important;
        }
        .invoice-img{
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
            border: 1px solid #9EA8AC !important;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 0 10px 0 rgba(0, 0, 0, 0.19);
        }
        .text-muted{
            color: #A8B1B5 !important;
        }
        .text-bold{
            color: $web-green-bg  !important;
            font-size: 24px !important;
            font-weight: 700 !important;
        }
        .fz-16{
            font-size: 16px !important;
        }
        .fz-17{
            font-size: 17px !important;
        }
        .invoice-download-box{
            background-color: $web-yellow-color;
        }
        .download-link{
            color: #ffffff;
            &:hover{
                text-decoration: none;
            }
        }
        .invoice-download{
            background-color: $web-yellow-color;
            .text-white{
                color: #ffffff;
            }
        }
    }

}