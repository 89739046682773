/*-------- 8. Footer style  -----*/


.cart-top {
  position: relative;
  bottom: 25px;
  right: 10px;
  h6 {
    color: red;
  }
}
.ticket {
  .col-xl-4 {
    flex: 1 0 50%;
    max-width: 50%;
    transition: 0.5s;
    background-color: red;
  }

  .ticket-title {
    position: relative;
    justify-self: start;
    align-self: start;
    width: 16rem;
    background-color: $web-yellow-color;
    border: none;
    border-bottom-right-radius: 30px;
    bottom: 23px;
  }
  h6 {
    font-size: 20px;
    // line-height: normal;
    letter-spacing: normal;
    display: block;
    color: #fff;
  }
  h4 {
    display: block;
    left: 16px;
    justify-self: start;
    align-self: start;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    position: relative;
    font-size: 14px;
    bottom: 15px;
    color: #b69931;
  }
}


.social-footer{
  display: flex;
  justify-content: space-evenly;
  width: 8%;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  @media #{$xs-layout}{
    width: 25%;
  }
  @media #{$md-layout}{
    width: 15%;
  }
  i{
    color: white;
    font-size: 20px;
  }
  svg{
    margin-block-end: 5px;
    color: white;
    font-size: 20px;
  }
}
.footer-area {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
  background-color: $web-green-color;
  &.noMenu{
    background-color: #185744;
    padding: 0 10px;
  }
  opacity: 1;
 .copyright{
   margin-inline-end: 15rem;
   @media screen and (min-width: 1900px) {
    margin-inline-end: 35rem;
   }
   @media #{$xs-layout}{
    margin-inline-end: 0rem;
    p{
      font-size: 10px;
    }
   }
 }
  @media #{$xl-layout} {
    &.ml-70 {
      margin-left: 15px;
    }
    &.mr-70 {
      margin-right: 15px;
    }
    .row {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
  }
  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 30px;
    }
    &.mr-70 {
      margin-right: 30px;
    }
    .row {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
  }
  @media #{$md-layout} {
    &.ml-70 {
      margin-left: 40px;
    }
    &.mr-70 {
      margin-right: 40px;
    }
    .row {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
  }
  @media #{$xs-layout} {
    &.ml-70 {
      margin-left: 15px;
    }
    &.mr-70 {
      margin-right: 15px;
    }
    .row {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
  }
}
