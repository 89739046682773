@import "~bootstrap/scss/bootstrap.scss";
@import "~swiper/css/swiper.css";
@import "~animate.css/animate.min.css";
@import "~lightgallery.js/dist/css/lightgallery.css";
@import "variables";
@import "helper";
@import "common";
@import "preloader";
@import "header";
@import "slider";
@import "support";
@import "section-title";
@import "footer";
@import "image-slider";
@import "breadcrumb";
@import "shop";
@import "checkout";
@import "contact";
@import "tickets";
@import "footer-links";
@import "social";
@import "404";
@import "_ql";
@import "_ticket";
@import "../css/icons.min.css";

@import url(//fonts.googleapis.com/css?family=Open+Sans);
// @import url(//db.onlinewebfonts.com/c/125063e353c1f65c55ba1e3811969217?family=GE+SS+Two+Medium);
@import url('//fonts.cdnfonts.com/css/gotham');
// @import url('http://fonts.cdnfonts.com/css/helvetica-255');

html,
body {
  margin: 0px !important;
  padding: 0px !important;
  text-align: inherit !important;
}

@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url(../fonts/Gotham/metropolis.regular.otf) format('truetype');
}

@font-face {
  font-family: 'GE SS Two';
  src: local('GE SS Two'), url(../fonts/Helvetica/GE_SS_TWO_LIGHT.OTF) format('truetype');
}

@font-face {
  font-family: 'Myriad Pro';
  src: local('Myriad Pro'),
  url(../fonts/MYRIADPRO-REGULAR.OTF) format('truetype');
}

body[dir='ltr'] {
  font-family: 'Gotham' !important;
}

body[dir='rtl'] {
  font-family: 'GE SS Two' !important;
}

.color-web-yellow {
  color: $web-yellow-color !important;
}

.color-web-yellow-hover {
  &:hover {
    color: $web-yellow-color;
  }
}

.color-web-grey {
  color: #2f2e2e;
}

.bg-web-gray {
  background-color: #2f2e2e;
}

.color-black {
  color: #000000;
  width: 200px;
}

.text-align {
  text-align: center;
}

// iframe {
//   pointer-events: none !important;
// }

.pointer-none {
  pointer-events: none;
}


.font-size-50 {
  font-size: 50px;
}

.font-size-27 {
  font-size: 18px;
  // overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  // color: red;
  opacity: 1;
  /* Firefox */
}

.font-size-16 {
  font-size: 16px;
}

.font-size-17 {
  font-size: 17px;
}

.pl-6 {
  padding-left: 6px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.pr-50 {
  padding-right: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-40 {
  padding-left: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.border-web-green-10 {
  border: 10px solid $web-green-color;
}

.border-light-green-10 {
  border: 10px solid lightgreen;
}

.border-web-yellow-10 {
  border: 10px solid $web-yellow-bg;
}

.hr1 {
  display: inline-block;
}

.hr1:after {
  content: '';
  // max-width: 90px;
  display: block;
  border-top: 2px solid $web-yellow-bg;
  margin-top: 0.2em !important;
  margin-bottom: 2px;
}


.border-light-pink-10 {
  border: 10px solid lightpink;
}

.no-margin {
  margin: 0px !important;
}

.btn-web-yellow {
  background-color: $web-yellow-color;
  border: none;
  color: white;
}

.btn-web-green {
  background-color: transparent;
  border: none;
  color: white;

}

.btn-warning {
  background-color: $web-yellow-color !important;
  color: white !important;
}

.four-box-section {
  padding-top: 80px;
  padding-bottom: 80px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .four-box-section-inner {
    padding-left: 40px;
    padding-right: 40px;

    @media #{$md-layout} {
      padding-left: 0px;
      padding-right: 0px;
    }

    @media #{$sm-layout} {
      padding-left: 0px;
      padding-right: 0px;
    }

    @media #{$xs-layout} {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .single-box {
    // background-color: rgba(255, 255, 255, 2);
    background-color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.1em;

    .budget {
      padding-top: 75px;
    }

    .description {
      padding-bottom: 60px;
    }
  }
}

.media-video-page-wrapper {
  padding: 20px;
  margin-bottom: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0 0 10px #0000000F;
  border: 0.1px solid #0E1815;
  border-radius: 12px;
}

.modal-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 141px;
  object-fit: cover;
}

.modal-image-1 {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 141px;
  object-fit: cover;
}

.media-pic-description {
  height: 16%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $web-yellow-color;
  overflow: hidden;

  p {
    color: #fff !important;
    font-weight: normal !important;
    overflow-wrap: break-word;
    overflow: hidden;
  }
}

.media-vid-description {
  height: 16%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $web-yellow-color;
  overflow: hidden;
  position: relative !important;
  bottom: 5% !important;

  a {
    text-decoration: none !important;
  }

  p {
    color: #fff !important;
    font-weight: normal !important;
    overflow-wrap: break-word;
    overflow: hidden;
  }
}

.watch-section-card {
  .watch-image-bg {
    @media #{$xxs-layout} {
      height: 100px;
    }
  }
}

.media-pic-2 {
  // justify-content: center;
  // justify-self: center;

}

.media-pic-ar-2 {
  // justify-content: center;
  // justify-self: center;

}

.media-vid {
  justify-content: center;
  justify-self: center;
  right: 15%;
}

.btn-success {
  background-color: $web-yellow-color !important;

}

option {
  direction: ltr;
}

.select-box-success {
  background-color: #ffffff !important;
  background-clip: padding-box;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  font-weight: bold;
  line-height: 1.5;
  border-radius: 0.1rem;
  // color: $web-green-color;
  display: block;
  width: 100%;
  border: 1px solid $web-green-color;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  option {
    min-height: 1.2em !important;
    padding: 0px 2px 1px !important;
  }
}

.shop-tab-new button {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.10000000149011612px solid #0E1815;
  border-radius: 6px;
  padding: 9px;
  box-shadow: 0px 0px 6px #0000001A;
  margin: 0 6px;
}

.select-box-new-design {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 0 6px #0000001A;
  border: 0.1px solid #0E1815;
  border-radius: 6px;
  padding: 4px 8px;
  color: #0E1815;
  line-height: 2;
  width: 228px;
  position: relative;

  select {
    height: 18px;
  }

  .select-box-new-design-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    background: #fff;
    padding: 1px 10px;
    border-radius: 10px;
  }

  option {
    line-height: 2;
    padding: 0px 2px 1px !important;
  }
}

.card-new {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  border-radius: 9px;
  padding: 25px;
  margin-bottom: 25px;

  .card-new-img {
    max-width: 445px;

    @media screen and (min-width: 768px) {
      max-width: 368px;
    }

    .allign-center {
      width: 100% !important;
      height: 206px;
      margin-bottom: 20px;

      img {
        height: 206px !important;
        width: 345px !important;
        max-height: 250px !important;
        max-width: 100%;
      }
    }
  }

  .card-new-date {
    font: normal normal normal 16px/18px Gotham;
    letter-spacing: 0px;
    color: #242424;
    margin-bottom: 8px;
  }

  .card-new-title {
    font: normal normal bold 20px/22px Gotham;
    letter-spacing: 0px;
    color: #0E1815;
    text-transform: capitalize;
  }

  .card-new-price {
    font: normal normal bold 24px/26px Gotham;
    letter-spacing: 0px;
    color: #006937;
  }

  .card-new-desc {
    height: 71px;
    margin-bottom: 0;
    overflow: hidden;
  }

  .read-more {
    letter-spacing: 0;
    color: #0E1815;
    font: normal normal medium 14px/15px Gotham;
    text-decoration: none;

    i {
      padding-inline-start: 2px;
      font-size: 22px;
      line-height: 13px;
      top: 2px;
      position: relative;
    }
  }

  .card-new-count {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0E181530;
    border-radius: 6px;
    display: flex;

    .card-new-count-btn {
      padding: 10px 15px;
      font: normal normal bold 26px/28px Gotham;
      color: #006937;
      cursor: pointer;
    }

    .card-new-count-text {
      padding: 10px 15px;
      font: normal normal bold 26px/28px Gotham;
      color: #000;
      cursor: default;
    }
  }

  .card-new-row {
    margin-right: -12px;
    margin-left: -12px;

    [class^='col'] {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .card-new-buy {
    background: #006937 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: #fff;
    padding: 13px 59px;
    cursor: pointer;
  }
}

.text-webgreen {
  color: $heading-color !important;
}

.text-black {
  color: $bg-black !important;
}


.outline-success {
  border: 1px solid $web-green-color !important;
  background-color: white !important;
  color: $web-green-color !important;

  &.nohover {
    &:hover {
      background-color: white !important;
      color: $web-green-color !important;
    }
  }

  &.sold {
    border: none !important;
    background-color: $web-green-color !important;
    color: white !important;
    width: 130px !important;
    // height: 50px !important;
  }

  &.green {
    // background-image: linear-gradient(0deg, rgb(210 158 14 / 65%), rgb(210 158 14 / 65%)), url(https://i.ibb.co/c2Vc3Mb/shutterstock-1766512784.jpg);
    color: #fff !important;
    width: 105px;
    background-color: $web-green-color !important;
    // border: 1px solid white !important;


    &:hover {
      background-color: $web-green-color !important;
      color: #fff !important;


    }
  }


  &.landing {
    color: #fff !important;
    width: 120px;
    background-color: black !important;

    &:hover {
      color: #fff !important;
      width: 120px;
      background-color: black !important;
      @media #{$xs-layout} {
        width: 110px;

      }
    }

    @media #{$xs-layout} {
      width: 110px;

    }
  }

  &.landing-2 {
    color: #A4A5A4 !important;
    width: 120px;
    background-color: white !important;
    // border: 1px solid white !important;
    &:hover {
      color: #A4A5A4 !important;
      width: 120px;
      background-color: white !important;
      @media #{$xs-layout} {
        width: 110px;

      }
    }

    @media #{$xs-layout} {
      width: 110px;

    }
  }

  &.ticket-btn {
    @media screen and (min-width: 800px) {
      width: 90px;
    }
  }

  &.news {
    height: 150px;
    width: 150px;
    border: 1px solid white !important;
    background-color: #466F57 !important;

    &:hover {
      border: 1px solid white !important;
      background-color: #466F57 !important;
    }
  }

  .btn {
    border: 2px solid $web-green-color !important;

  }

  .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.0rem $web-green-color !important;
  }

  .btn:active {
    outline: 0;
    box-shadow: 0 0 0 0.0rem $web-green-color !important;
  }


  .btn-seemore:focus {
    outline: 0;
    box-shadow: 0 0 0 0.0rem $web-green-color !important;
  }

  &:hover {
    background-color: $web-yellow-color !important;
    color: #fff !important;
    border: 1px solid $web-yellow-color !important;

  }
}

.btn-solid-green {
  background-color: $web-green-color !important;
  color: white !important;

  .btn {
    border: 2px solid $web-green-color !important;
    color: white !important;
  }

  &:hover {
    background-color: $web-green-color !important;
    color: white !important;
  }
}

.btn-outline-solid-green {
  // color: #185729 !important;
  border-color: $web-green-color !important;
}

.btn-outline-solid-green:focus,
.btn-outline-solid-green:hover {
  color: #fff !important;
  background-color: $web-green-color !important;
  border-color: $web-green-color !important;
}

.btn-outline-green {
  // color: #1C4027 !important;
  color: $web-green-color !important;
  border-color: $web-green-color !important;

  &:hover {
    color: #fff !important;
    background-color: black !important;
    border-color: black !important;
  }
}

.mrl-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.lr-20 {
  left: 20px !important;
  position: relative;
}

.active-nav-link {
  border-bottom: 2px solid $web-green-color;
}

.nav-link-space {
  padding: 0.5rem 1rem !important;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: $web-yellow-color !important;
}

.nav-hover-color:focus,
.nav-hover-color:hover {
  color: $web-yellow-color !important;

}

.nav-hover-color .a {
  :hover {
    color: $web-yellow-color !important;
  }

}


.imghover:hover {
  filter: brightness(97%) contrast(91%);
  transform: scale(1.2);
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #ffffff !important;
  background-color: $web-green-color !important;
  // background-color: #ccc !important;
}

// .dropdown-menu {
//   -webkit-transition: all 0.5s ease-in-out !important;
//   transition: all 0.5s ease-in-out !important;
//   // transform: rotateX(90deg) !important;
//   // transform-origin: top !important;
//   opacity: 0 !important;
//   // display: block !important;

// }
// .dropdown:hover .show {
//   opacity: 1 !important;
//   // transform: rotateX(0deg) !important;
//   // transform-origin: top !important;
//   -webkit-transition: all 0.5s ease-in-out !important;
//   transition: all 0.5s ease-in-out !important;


// }

.dropdown-item:focus,
.dropdown-item:hover .lang {
  color: #ffffff !important;
  background-color: #ffffff !important;
}

.dropdown-menu .active {
  color: #ffffff !important;
  background-color: $web-green-color !important;
  font-size: 14px !important;
}

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .nav-link {
  }

  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }

  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}

.dropdown-menu {
  font-size: 14px !important;
}

.lang {
  a {
    color: #ffffff;
  }

  .dropdown-menu .active {
    color: #353a3f !important;
    background-color: #ffffff !important;
  }

  .dropdown-menu {
    color: #ffffff !important;
    background-color: $web-green-color !important;

  }

  .dropdown-item {
    color: #ffffff !important;
    font-weight: 0 !important;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #353a3f !important;
    background-color: #ffffff !important;
  }
}

/* .dropdown-navbar .dropdown-menu {
  -webkit-transition: all 2s !important;
  -moz-transition: all 2s !important;
  -ms-transition: all 2s !important;
  -o-transition: all 2s !important;
  transition: all 2s !important;
  display: block;
  overflow: hidden;
  opacity: 0;
  transform: translateX(-25%) scaleY(0) !important;
  transform-origin: top !important;
}
.dropdown-navbar .dropdown-menu.show {
  opacity: 1;
  transform: translateX(-25%) scaleY(1) !important;
} */

.btn-filter-dropdown {
  border-color: $web-green-color !important;
  font-size: 14px !important;
}

.btn-filter-dropdown:focus,
.btn-filter-dropdown:hover {
  color: #ffffff !important;
  font-size: 14px !important;
}

.btn-group > .btn:active {
  z-index: 1;
  background-color: $web-green-color !important;
  color: #ffffff !important;
}

.filter-dropdown-btn {
  background-color: $web-green-color !important;
  color: #ffffff !important;
}

.global-main-title {
  font-size: 40px !important;
  font-weight: normal !important;
}

.global-sub-title {
  font-size: 20px !important;
  line-height: inherit;

  @media #{$xs-layout} {
    font-size: 15px !important;
  }

  font-weight: inherit;
  opacity: 1;
  line-height: inherit;

  &.white {
    color: white;
  }
}

.global-view-all {
  border-bottom-width: 0.4px;
  border-bottom-style: solid;
  color: $web-yellow-color;
  font-size: 20px !important;
  font-weight: inherit;

  @media #{$xs-layout} {
    font-weight: inherit;
    line-height: inherit;
    font-size: 15px !important;

  }

  opacity: 1;
  line-height: inherit;
  text-decoration: none;

  &:hover {
    color: $web-green-color;
  }

  &.white {
    color: white;
  }
}

.global-body-title {
  font-size: 32px !important;
  font-weight: 700 !important;
  color: $heading-color;

  &.ticket {
    font-size: 25px !important;
  }

  &.green {
    color: $web-green-color;
  }
}

.global-body-title-2 {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: $heading-color;
}

.global-body-subtitle-footer {
  /*   font: normal normal normal 20px/24px ; */
  letter-spacing: 0px;
  opacity: 1;
}

.global-body-subtitle {
  font-size: 18px !important;

  &.hrh {
    font-size: 15px !important;
  }

  font-weight: 700 !important;
}

.global-body-text {
  font-size: 14px !important;
  font-weight: normal !important;

}

.global-body-text-date {
  font-size: 12px !important;
  font-weight: normal !important;

}

.global-price-text {
  font: normal normal bold 24px/34px Myriad Pro;
}

.global-body-text-footer {
  letter-spacing: 0px;
  opacity: 1;
  font: normal normal normal 12px/15px;
}


.global-text-gold {
  color: $web-yellow-color !important;
}

.react-parallax-bgimage {
  object-fit: cover;
}

.btn-ticket {
  // background: $web-yellow-color !important;
  background-image: linear-gradient(0deg, rgb(210 158 14 / 65%), rgb(210 158 14 / 65%)), url(https://i.ibb.co/c2Vc3Mb/shutterstock-1766512784.jpg);
  border-color: $web-yellow-color !important;
  color: #ffffff !important;
  text-align: center !important;
  font-size: 14px !important;
  text-decoration: none !important;
  width: 140px !important;
  line-height: 2.8 !important;
  box-shadow: 0 .5rem 1rem rgba(#000000, .15) !important;

  @media #{$md-layout} {
    margin: 0px 5px 5px 5px;
  }

  @media #{$sm-layout} {
    margin: 0px 5px 5px 5px;
  }

  @media #{$xs-layout} {
    margin: 0px 5px 5px 5px;
  }

  .ticket-icon {
    -webkit-transform: rotate(-45deg) !important;
    -moz-transform: rotate(-45deg) !important;
    -ms-transform: rotate(-45deg) !important;
    -o-transform: rotate(-45deg) !important;
    transform: rotate(-45deg) !important;
    font-size: 17px !important;
  }
}

.swiper-pagination {
  @media #{$xs-layout} {
    // position: relative;
    margin-top: 25px;
    // left: 15% !important;
    // margin-inline-start: 60px;
  }

  // margin-bottom: 5px;

  .swiper-pagination-bullet {
    background: #fff !important;
    width: 20px;
    height: 20px;
  }
}

.swiper-pagination-landing {
  @media #{$xs-layout} {
    position: relative;
    margin-top: 35px;
  }
  left: 0% !important;


  // margin-bottom: 5px;

  .swiper-pagination-bullet {
    background: #2C5645 !important;
    width: 20px;
    height: 20px;
  }
}

.textunder {
  text-decoration: underline;
  height: 10px;
  line-height: 10px;
}

.no-border-l {

  &:focus {
    border: none;
    border-color: red;
  }
}

.border-checkout {
  &:focus {
    box-shadow: 0 0 0 0rem !important;
  }
}

.btn.btn-light.focus,
.btn.btn-light:focus {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  outline: none;
  box-shadow: none;
}

.color-whits {
  filter: brightness(0) invert(1);
}

.table-border-radius {
  border: 1px solid #dee2e6 !important;
  border-radius: 10px 10px 0px 0px !important;
}


.border-line {
  border-right: 1px solid #000;
}

.push-left {
  position: absolute;
  right: auto;
}

.push-right {
  position: absolute;
  left: auto;
}

.vis-hidden {
  visibility: hidden;
}

.mr-fot {
  margin-right: 90px;

  @media #{$sm-layout} {
    margin-right: 0px;
  }

  @media #{$xs-layout} {
    margin-right: 0px;
  }
}

.ml-fot {
  margin-left: 90px;

  @media #{$sm-layout} {
    margin-left: 0px;
  }

  @media #{$xs-layout} {
    margin-left: 0px;
  }
}

.option {
  color: $bg-black !important;
  background-color: $bg-white !important;
}

.download-button {
  position: relative;
  right: 90px;
  bottom: 5px;

  @media #{$xs-layout} {
    position: relative;
    left: 90px;
    bottom: 22px;
  }

  @media #{$md-layout} {
    position: relative;
    left: 90px;
    bottom: 22px;
  }
}

.download-button-ar {
  position: relative;
  left: 140px;
  bottom: 5px;

  @media #{$xs-layout} {
    position: relative;
    left: 140px;
    bottom: 25px
  }

  @media #{$md-layout} {
    position: relative;
    left: 140px;
    bottom: 25px
  }
}

.ql-video {
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.top-144 {
  margin-top: 70px;

  @media #{$xs-layout} {
    margin-top: 100px;

  }
}

.top-90 {

  margin-top: 90px;
}

.select {

  word-wrap: none !important;
}

.space-between {

  justify-content: space-between;
}

.paddin-2 {
  padding: 2px 10px 2px;

}

.lepoint {
  li {
    list-style: none;

    &.none {
      list-style: outside none none !important;
    }

    background: url(https://i.ibb.co/c8hHVG4/Asset-1.png) no-repeat 0px 0px transparent;
    background-position-x: 1px;
    padding-left: 32px;
    line-height: 2.5;
  }

  li::before {
  }

}

.lepoint-ar {
  li {
    list-style: none;

    &.none {
      list-style: outside none none !important;
    }

    // background: url(https://i.ibb.co/c8hHVG4/Asset-1.png) no-repeat 0px 0px transparent;
    background-position-x: 1px;
    padding-left: 32px;
    line-height: 2.5;
  }

  li::before {

    content: '';
    display: inline-block;
    height: 18px;
    width: 18px;
    background-image: url('https://svgshare.com/i/e4v.svg');
    background-repeat: no-repeat;
    // padding: 6px 0 6px 4px;
    margin-inline-end: 5px;


  }

}

.list-none {
  list-style: outside none none !important;

}

.purple-border {
  box-shadow: 10px -10px 2px #D29E0E;
  -moz-box-shadow: 10px -10px 2px #D29E0E;
  -webkit-box-shadow: 10px -10px 2px #D29E0E;
  -khtml-box-shadow: 10px -10px 2px #D29E0E;
}


.head-dropdown {
  position: relative;
  display: inline-block;

  &:hover .dropdown-content {
    display: block;
  }

  &:hover .dropbtn {
    background-color: $web-green-color !important;
    color: $web-yellow-color !important;
  }

  .dropbtn {
    background-color: $web-green-color !important;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;

    .dropdown-caret {
      margin-top: -5px !important;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    float: left;
    min-width: 12rem;
    padding: 0.5rem 0;
    z-index: 1000;
    margin-top: -7px !important;
    border-radius: 0.25rem;
    color: #ffffff !important;
    background-color: $web-green-color !important;
    font-size: 14px !important;
  }

  .dropdown-content a {
    color: #ffffff !important;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
  }

  .dropdown-content a:hover {
    color: #353a3f !important;
    background-color: #ffffff !important;
  }

  .dropdown-content .active {
    color: #353a3f !important;
    background-color: #ffffff !important;
  }
}

.text-decoration-none {
  text-decoration: none;
}

.pointer-non {
  pointer-events: none !important;
}

.white-nowrap {
  white-space: nowrap !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-left {
  text-align: left !important;
}

.lg-sub-html {
  background: #ffffffc4 !important;
  color: black !important;

  p {
    color: black !important;
    font-size: 16px;
  }
}

.nocursor {
  cursor: auto !important;
}

.lg-outer {
  direction: ltr;

}

.text-a-white {
  a {
    color: white !important;
    margin-inline-start: 10px;
  }

  i {
    color: white !important;
  }
}

.vis-collapse {
  display: none;
}

.fbhklE {
  font-weight: normal !important;
}

.margin-block-s80 {
  position: absolute;
  left: 50%;
  display: block;
  width: 1.8in;
  bottom: 5px;
}

.custom-pagination-box {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  ul.custom-pagination {
    display: inline-block;
    padding: 0;
    margin: 0;

    li {
      display: inline;
      margin: 0px 8px;

      a {
        display: inline-block;
        width: 43px;
        height: 43px;
        text-align: center;
        line-height: 43px;
        font-size: 16px;
        background: none;
        border: none;
        border-radius: 100% !important;
        padding: 0;
        color: $web-green-color;
        box-shadow: 0 0 12px 0.8px rgb(0 0 0 / 10%);
      }

      a:hover:not(.active) {
        background-color: #ddd;
        color: $web-green-color;
      }
    }

    li.selected {
      a {
        background-color: $web-green-color;
        color: #fff;
      }
    }
  }
}

.margin-auto {
  margin: auto;
}

.margin-left-7percent {
  margin-left: 7%;
}


.has-search {
  margin-top: 0.5rem !important;
  margin-bottom: 2rem !important;

  .form-control {
    border-radius: 0px !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid #eaeaea;
    box-shadow: none !important;
  }

  .form-control:focus {
    border-bottom: 1px solid $web-green-bg !important;
  }

  .form-control {
    padding-bottom: 1.5rem;
    margin-left: 0.5rem;
  }

  .p-l-6 {
    padding-left: 3.375rem;
  }

  .p-r-6 {
    padding-right: 3.8rem;
  }

  .search-icon {
    position: absolute;
    top: -3px;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    font-size: 35px !important;
  }

  .search-icon-left {
    left: 30px;
  }

  .search-icon-right {
    right: 30px;
    transform: scaleX(-1) !important;
  }

  .search-field::placeholder {
    color: #d8d8d8 !important;
  }

  .light-color {
    color: #d8d8d8 !important;
  }

  .green-color {
    color: $web-green-bg !important;
  }
}

.tooltip-inner {
  background-color: $web-disable !important;
  // opacity: 1;
}

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $web-disable !important;
  // visibility: hidden;
  // opacity: 1;
}

.danger-text {
  color: red;
  margin-inline-start: 10px;
}

.line-height-40 {
  line-height: 40px !important;
}

.mx-width-70p {
  max-width: 73% !important;
}

.placesView {
  height: 200px;

  &.allstyle {
    width: 250px;

    @media screen and (min-width: 1800px) {
      width: 350px;
    }
  }

  @media screen and (min-width: 1800px) {
    height: 300px;
  }

  box-shadow: 0px 3px 25px #00000033;
  border-radius: 28px;

  img {
    height: 100%;
    object-fit: cover;
    border-radius: 28px;
    width: 100%;
  }

  .overlay {
    border-bottom-left-radius: 30px;
    background: #0E1815 0% 0% no-repeat padding-box;
    color: #f1f1f1;
    opacity: 0.8;
    width: 100%;
    bottom: 47px;
    position: relative;
    border-bottom-right-radius: 30px;
    text-align: center;
    height: 47px;
    vertical-align: middle;
    padding-top: 13px;
    transition: .5s ease-in-out;


    .city-name {
      justify-content: center;
      align-items: center;
      align-self: center;
      font: normal normal bold 20px/22px Gotham;
      letter-spacing: 0px;
      color: #FFFFFF;
    }

    .ciyt-info {
      margin-top: 17px;
      justify-content: space-around;
      align-items: center;
      align-self: center;
      font: normal normal normal 14px/15px Gotham;
      letter-spacing: 0px;
      color: #FFFFFF;

      .info-text {
        font-weight: lighter;
        opacity: 1;
        font-size: 15px;
      }
    }
  }

  &:hover {
    .overlay {
      overflow: hidden;
      height: 96px;
      transform: translateY(-50px);
    }
  }
}

.cupsView {
  height: 223px;
  background-color: white;

  // width: 250px;
  &.allstyle {
    width: 100%;
  }


  box-shadow: 0px 3px 25px #00000033;
  border-radius: 28px;

  img {
    height: 100%;
    object-fit: cover;
    //border-radius: 28px;
    width: 100%;
    //border: solid $web-yellow-color;
    //border-width: 5px 5px;

  }
}

.cup-title-view {
  background: #fff;
  color: $web-yellow-color;
  width: 80%;
  bottom: 3rem;
  position: relative;
  text-align: center;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
  border: solid white;
  border-width: 1px 1px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  left: 50%;

  &.allstyle {
    width: 220px;

    @media screen and (min-width: 1800px) {
      width: 320px;
    }
  }

  transform: translateX(-50%);

  &.ar {
    left: 30% !important;

    &.allstyle {
      left: 42% !important;
    }

    @media screen and (min-width: 1900px) and (max-width: 2000px) {
      left: 36% !important
    }
  }
}

.cup-title-view2 {
  overflow: hidden;
  position: relative;
  padding-bottom: 25px;

  .cupsView {
    box-shadow: 0px 0px 15px #00000040;
    z-index: 2;
  }

  .cup-title-bg {
    background: $web-green-color;
    z-index: 1;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 25px;

    &.cup-title-bg-white {
      background: #fff;
      box-shadow: inset 0 10px 17px -14px #00000040;
    }
  }

  .cup-title-block {
    display: initial;
    text-align: center;
    position: absolute;
    bottom: -25px;
    right: 0;
    left: 0;


    .cup-title-btn {
      padding: 12px 18px;
      box-shadow: 0 8px 15px 0 rgb(0, 0, 0, 50%);
      background: #fff;
      display: inline;
      z-index: 2;
      position: relative;
      font-size: 10px;
      font-family: Gotham;
      letter-spacing: 0px;
      color: $web-green-color;
    }

    .cup-title-desc {
      height: 10px;
      background: #fff;
      position: relative;
      top: -10px;
      -webkit-transition: height 1s;
      -moz-transition: height 1s;
      -ms-transition: height 1s;
      -o-transition: height 1s;
      transition: height 1s;
      padding: 29px 10px 10px;
      color: #999;

      .cup-title {
        font-size: 14px;
        font-weight: bold;
        color: #333;
      }

      .cup-title-icon {
        background: #d29e0d;
        display: inline-block;
        padding: 0px 10px;
        color: #fff;
      }
    }
  }

  &:hover {
    .cup-title-desc {
      height: 170px;
    }
  }
}

.lastTicketView {
  height: 250px;

  &.landing {
    height: 418px;
  }

  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);

    .img-gradient img {
      -webkit-transform: scale(1.05, 1.05);
      -moz-transform: scale(1.05, 1.05);
      transform: scale(1.05, 1.05);
    }

    .img-gradient:after {
      transform: scale(1.05, 1.05);
    }
  }

  &.allstyle {
    width: 350px;
  }

  @media #{$xs-layout} {
    &.landing {
      height: 305px !important;
    }
  }
  @media screen and (min-width: 1800px) {
    height: 300px;

    &.allstyle {
      width: 350px;
    }

  }

  box-shadow: 0px 3px 25px #00000033;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 4px 18px #00000024;
  opacity: 1;

  .img-gradient {
    max-width: 100%;
    width: 90%;
    opacity: 1;
    height: 80%;
    margin: auto;
    display: inline-block;
    // overflow: hidden;
  }

  .img-gradient img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 10px;
    border: solid white;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    -webkit-transition: .5s ease;
    -moz-transition: .5s ease;
    transition: .5s ease;
  }

  .img-gradient::after {
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: block;
    position: relative;
    background: transparent linear-gradient(183deg, transparent, #0d1311ba) 0 0 no-repeat padding-box;
    margin-top: -11.5rem;

    &.landing {
      margin-top: -18.5rem;

    }

    height: 100%;
    width: 100%;
    content: '';
    -webkit-transition: .5s ease;
    -moz-transition: .5s ease;
    transition: .5s ease;

    @media screen and (min-width: 1800px) {
      margin-top: -14.5rem;
      &.landing {
        margin-top: -27.8rem;

      }
    }
  }

  .img-gradient-landing {
    max-width: 100%;
    width: 90%;
    opacity: 1;
    height: 80%;
    margin: auto;
    display: inline-block;
    // overflow: hidden;
  }

  .img-gradient-landing img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 10px;
    border: solid white;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    -webkit-transition: .5s ease;
    -moz-transition: .5s ease;
    transition: .5s ease;
  }

  .img-gradient-landing::after {
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: block;
    position: relative;
    background: transparent linear-gradient(183deg, transparent, #0d1311ba) 0 0 no-repeat padding-box;
    margin-top: -11.5rem;
    margin-top: -18.5rem;
    height: 100%;
    width: 100%;
    content: '';
    -webkit-transition: .5s ease;
    -moz-transition: .5s ease;
    transition: .5s ease;
    @media #{$xs-layout} {
      margin-top: -11.5rem;

    }
  }


  .image-info {
    position: relative;
    bottom: 1.5rem;
    left: 0;
    color: #fff;
    width: 85%;
    margin: 0;
    display: flex;
    justify-content: space-around;

    &.landing {
      justify-content: space-between;
    }

    align-items: center;

    .package-name {
      font: normal normal bold 16px/18px Gotham;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
    }

    .city-name {
      width: 35%;
      font-size: 15px;
      background-color: #B0B1B1;
      border-radius: 5px;
      height: 100%;
      justify-content: center;
      align-items: center;

      p {
        color: white;
      }
    }

    .date-text {
      position: absolute;
      right: 0px;
      bottom: 35px;
      font: normal normal bold 15px;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
    }
  }

  .lastTicketInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;

    .price {
      font-weight: bold;
      color: $web-yellow-color !important;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      width: 50%;

      @media #{$xs-layout} {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .eventName {
      // margin-inline-end: 5rem;
      @media (min-width: 2000px) {
        margin-inline-start: 2rem;
      }
      @media (min-width: 500px) and (max-width: 800px) {
        margin-inline-end: 0rem !important;
      }
      @media #{$xs-layout} {
        // margin-inline-end: 0rem;
      }
      margin-inline-start: 1rem;
      text-align: start;
      color: #919191;
      font-size: 18px;
      font-weight: normal;
      width: 50%;
    }
  }


}

.remi-ticket2 {
  position: absolute;
  width: 140px;
  height: 40px;
  background: white;
  line-height: 40px;
  border-radius: 23px;
  top: 15px;
  left: 30px;
  opacity: 0.6;
  color: #25bba9;
  z-index: 999;
  display: flex;
  justify-content: center;
}

.lastTicketView2 {
  transition: all .3s ease-in-out;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 4px 18px rgb(0 0 0 / 14%);
  opacity: 1;
  padding: 10px;

  .img-gradient {
    width: 100%;

    img {
      width: 100%;
      height: 235px;
      border-radius: 15px;
      object-fit: cover;
    }
  }

  .p-17 {
    padding-bottom: 17px;
  }

  .lastTicketInfo {
    margin-top: 12px;
    width: 100%;
  }

  .lastTicketData {
    font-size: 12px;
    color: #0e1815;
  }


  .lastTicketTitle {
    font: normal normal bold 16px/18px Gotham;
    font-weight: bold;
    color: #0E1815;
    margin-bottom: 6px;
  }

  .lastTicketDesc {
    font: normal normal normal 13px/14px Gotham;
    color: #919191;
  }

  .lastTicketBtn {
    font: normal normal normal 11px Gotham;
    color: #0E1815;
    background: #e9e9e9;
    border-radius: 8px;
    padding: 12px 11px;
  }

  .lastTicketPrice {
    font: normal normal bold 18px/20px Gotham;
    letter-spacing: 0px;
    color: #006937;
  }
}

.event-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  //@media screen and (min-width: 1900px) {
  //  margin-right: 5.5rem;
  //  margin-left: 5.5rem;
  //}
}

.shop-area {
  background: #F9F9F9;
  padding-top: 30px;
}

.event-tiem-image {
  position: relative;
  bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    align-self: center;
    justify-content: center;
    justify-self: center;
    align-items: center;
    width: 90%;
    height: 400px;

    @media screen and (min-width: 1900px) {
      // height: 600px;
    }

    @media #{$xs-layout} {
      height: 100%;
      margin-top: 20px;
    }

    object-fit: cover;
    background: 50%;
    background-size: cover;
    border-radius: 16px;
    opacity: 1;
  }
}

.event-time-2 {
  position: relative;
  background: #F9F9F9;

  .global-body-icon {
    content: "";
    background: #fff;
    position: absolute;
    bottom: 0;
    height: 31px;
    width: 31px;
    border-radius: 50%;
    left: 50%;
    transform: translate(50%, 50%);

  }

  .event-time-header {
    box-shadow: 0 3px 10px #0000001A;
    padding-bottom: 20px;
    background: #fff;
    margin-bottom: 50px;
  }

  .event-time-image2 {
    padding-top: 100px;
    margin-bottom: 17px;

    img {
      vertical-align: middle;
      border-style: none;
      height: 328px;
      object-fit: cover;
      width: 100%;
    }
  }

  .event-cup-details {
    .event-logo {
      width: 230px;
      height: 134px;
      background: #FFFFFF;
      border: 0.2px solid #0E1815;
      border-radius: 18px;
      margin-bottom: 0;
    }

    .event-logo-info {
      .logo-title {
        font: normal normal bold 28px/31px Gotham;
        letter-spacing: 0px;
        color: #0E1815;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      .logo-info {
        font: normal normal bold 15px/17px Gotham;
        letter-spacing: 0;
        color: #0E1815;
        margin-bottom: 15px;
      }

    }
  }

  .event-cup-price {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 120px;
    padding-top: 14px;
    justify-content: space-between;

    .price {
      font: normal normal bold 15px/12px Gotham;
      color: #0E1815;
    }

    .StartedFrom {
      font: normal normal normal 14px/12px Gotham;
      color: #919191;
      margin-bottom: 8px;
    }

    .Price {
      font: normal normal bold 28px/31px Gotham;
      color: #0E1815;
      white-space: nowrap;
      margin-bottom: 15px;
    }
  }

  .event-time-desc {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 10px #0000000F;
    border: 0.1px solid #0E1815;
    border-radius: 12px;
    padding: 25px 40px;
    overflow: hidden;

    .global-body-text {
      overflow: hidden;
      max-height: 90px;
      transition: max-height 0.5s ease-in;

      &.show {
        max-height: 1000px;
      }
    }

    .event-time-desc-title {
      font: normal normal bold 18px/20px Gotham;
      letter-spacing: 0;
      color: #0E1815;
    }
  }
}


.event-cup-details-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-start: 20px;

  .event-logo {
    width: 35%;
    height: 100px;
    border-radius: 10px;
    opacity: 1;
    border: 1px solid #A5A8A7;
    background-color: white;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      align-self: center;
      width: 85%;
      height: 85%;
      object-fit: cover;
      border-radius: 10px;

    }
  }

  .event-logo-info {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-inline-start: 25px;
    margin-bottom: 25px;

    .logo-title {
      text-align: initial;
      font-size: 20px;
      font-weight: 500;
      color: #0E1815;
      opacity: 1;
    }

    .logo-info {
      text-align: left;
      font-size: 13px;
      letter-spacing: 0px;
      color: #0E1815;
      opacity: 1;
    }

    .event-city {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-inline-end: 10px;
    }

    .event-city-location {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      background-color: #919191;
      margin-inline-end: 5px;
      opacity: 1;
      border-radius: 5px;
    }

    .calendar {
      margin-inline-end: 5px;
    }
  }
}

.event-cup-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .event-logo {
    width: 120px;

    @media #{$xs-layout} {
      // width: 100%;
    }

    height: 120px;
    border-radius: 10px;
    opacity: 1;
    border: 1px solid #A5A8A7;
    background-color: white;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      align-self: center;
      width: 85%;
      height: 85%;
      object-fit: cover;
      border-radius: 10px;

    }
  }

  .event-logo-info {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-inline-start: 25px;

    .logo-title {
      text-align: initial;
      font-size: 20px;
      font-weight: 500;
      color: #0E1815;
      opacity: 1;
    }

    .logo-info {
      text-align: left;
      font-size: 15px;
      font-weight: 600;
      color: #0E1815;
      opacity: 1;
      // margin-top: 5px;
    }
  }

  .event-city {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-inline-end: 10px;
  }

  .event-city-location {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #919191;
    margin-inline-end: 5px;
    opacity: 1;
    border-radius: 5px;
  }

  .calendar {
    margin-inline-end: 5px;
  }
}

.event-cup-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 120px;

  h1 {
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    color: #0E1815;
    opacity: 1;
  }

  p1 {
    opacity: 1;
    color: #C0C0C0;
  }

  p2 {
    font-weight: bold;
  }
}

.event-cup-price-mobile {
  margin-right: 1rem;
  /* width: 90%; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 1rem;
  margin-top: 10px;

  h1 {
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    color: #0E1815;
    opacity: 1;
  }

  h2 {
    opacity: 1;
    color: #C0C0C0;
    font-size: 10px;
  }

  p2 {
    font-weight: bold;
  }
}

.event-pargraph {
  display: flex;
  justify-content: center;
  align-items: center;

  .pargraph {
    width: 90%;
  }
}

.blog-area {
  @media #{$xs-layout} {
    .compress-row-layer {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
  }
}

.margin-inline-start-2r {
  margin-inline-start: 2rem !important;
}

.hero-title {

  display: flex;
  align-items: center;
  justify-content: center;

  .box {
    padding: 0 15px;
    height: 50px;
    border-radius: 10px;
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    border: 2px solid #FFFFFF;
    opacity: 1;
    margin-top: 6rem;
    margin-bottom: 6rem;

    @media screen and (min-width: 1900px) {
      margin-top: 10rem;
      margin-bottom: 10rem;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    h {
      text-align: center;
      font: normal normal bold 20px Gotham;
      letter-spacing: 0px;
      color: #FFFFFF;
      text-shadow: 0px 2px 4px #0000004D;
      text-transform: uppercase;
      opacity: 1;
    }
  }
}

.layer {
  background: #0E1815 0% 0% no-repeat padding-box;
  opacity: 0.75;
  // height: 455px;
}

.allitems-row {
  @media #{$xs-layout} {
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 500px) and (max-width: 800px) {
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 1900px) {
    // justify-content: space-between;
    align-items: center;
  }
}

.mb-200 {
  margin-bottom: 200px !important;
}

.mb-200-large {
  @media screen and (min-width: 1500px) {
    margin-bottom: 200px !important;

  }
}

.hero-height {
  height: auto;
  width: 100%;

  @media #{$xs-layout} {
    height: 100%;
  }
}

.react-parallax-bgimage {
  @media #{$xs-layout} {
    left: 0 !important;
    transform: none !important;
    transform-style: flat;
    backface-visibility: visible;
  }
}

.limit-text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.limit-text-2 {
  // overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.limit-text-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 3em;
}

.overflow-break {
  overflow-wrap: break-word;
}

.tickets-hero {
  justify-content: center;
  align-items: center;

  .ticket-img-cont {

    width: 100%;
    justify-content: center;
    align-items: center;
    background: #0E1815 0% 0% no-repeat padding-box;
    border-radius: 16px;
    background-size: cover;
    align-self: center;

    img {
      width: 100%;
      height: 200px;

      @media screen and (min-width: 1900px) {
        height: 300px;
      }

      border-radius: 16px;
      opacity: 0.65;
      object-fit: cover;
      background: center;
      background-size: cover;
      // object-position: 20% 10%;

    }
  }

  .ticket-hero-info {
    position: relative;
    justify-content: flex-start;
    align-items: center;
    display: grid;
    bottom: 8rem;

    @media screen and (min-width: 1900px) {
      bottom: 11rem;
    }

    margin-bottom: -50px;
    margin-inline-start: 2rem;

    h1 {
      color: white;
      font-size: 20px;

      @media #{$xs-layout} {
        font-size: 15px;
      }
    }

    .ticket-hero-info-image {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;

      p1 {
        color: white;
        font-size: 12px;

        @media #{$xs-layout} {
          font-size: 10px;
        }

        margin-inline-end: 10px;
        margin-inline-start: 5px;
      }
    }

    .ticket-hero-location {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      background-color: white;
      margin-inline-end: 5px;
      opacity: 1;
      border-radius: 5px;
    }
  }
}

.ticket-hero-share {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;

  &.ar {
    left: 0;
  }

  &.en {
    right: 0;

  }

  bottom: 5rem;
  @media #{$xs-layout} {
    bottom: 0rem;
    margin-block-start: 50px;

  }

  @media screen and (min-width: 1900px) {
    bottom: 8rem;
  }

  margin-inline-end: 15px;

  .i {
    margin-inline-end: 10px;
  }

  p1 {
    color: white;
    margin-inline-start: 5px;
    font-size: 15px;
  }

}

.color-white {
  color: white !important;

}

.ticket-view-contain {
  margin-bottom: 30px;

  a {
    color: inherit !important;
    text-decoration: inherit !important;

    &:hover {
      color: inherit !important;
      text-decoration: inherit !important;
    }
  }

  .remi-ticket {
    position: absolute;
    width: 140px;
    height: 40px;
    background: white;
    line-height: 40px;
    border-radius: 23px;
    top: 55px;

    &.landing {
      @media #{$xs-layout} {
        top: 20px
      }
    }

    left: 48px;
    opacity: 0.6;
    color: #25bba9;
    z-index: 999;
    display: flex;
    justify-content: center;

    p1 {
      font-size: 14px;
    }
  }

  &:hover {
    .remi-ticket {
      opacity: 1;

    }
  }
}

.custom-search {
  position: relative;
  width: 100%;
}

.custom-search-input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding: 10px 100px 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  padding-inline-start: 20px;
}

.custom-search-botton {
  position: absolute;

  // right: -5px;
  // left: -5px;
  &.ar {
    left: -5px;
  }

  &.en {
    right: -5px;
  }

  top: -1px;
  bottom: 9px;
  border: 0;
  background: $theme-color;

  &.cancel {
    background: $web-red;

    &.ar {
      left: -11px;
    }

    &.en {
      right: -11px;
    }

    // right: -11px; 
  }

  color: #fff;
  outline: none;
  margin: 0;
  padding: 0 10px;
  border-radius: 22px;
  z-index: 2;
}

.align-item-center {
  align-items: center !important;
}

.mx-width-50p {
  max-width: 80%;
  @media #{$xs-layout} {
    max-width: 100%;
  }
}

.landing-image {
  @media #{$xs-layout} {
    height: 700px;
  }
}

.landing-text {
  font-size: 60px;
  line-height: inherit;
  font-weight: bold;
  opacity: 1;
  line-height: inherit;
  color: #185744 !important;

  @media #{$xs-layout} {
    width: 100%;
    font-size: 30px;
  }
}

.landing-sub-text {
  color: #CCCCCC !important;
  margin-top: 1.5rem;
  @media #{$xs-layout} {
    width: 100%;
    font-size: 25px;
    margin-top: 0px;
  }

}


.low-highlight {
  background: linear-gradient(180deg, transparent 70%, #AA8125 60%);
}

.mob-ticket-width {
  @media #{$xs-layout} {

    padding-right: 1px !important;
    padding-left: 1px !important;
  }
}

.mob-ticket-width-button {
  @media #{$xs-layout} {

    justify-content: space-around;
    align-items: center;
  }
}

.description-section {
  .readmore {
    position: relative;
    // max-height: 70px;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
  }

  .readmore.expand {
    // max-height: 1000px !important;
    transition: max-height 0.35s ease-in-out;
  }

  .readmore-link {
    display: block;
    cursor: pointer;
    // color: #FFFFFF;
  }

  .readmore-link.expand {
    position: relative;
  }
}

.row-expand {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-ticket-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;

  .price {
    font-weight: bold;
    color: $web-yellow-color !important;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    width: 50%;

    @media #{$xs-layout} {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .eventName {
    // margin-inline-end: 5rem;
    @media (min-width: 2000px) {
      margin-inline-start: 2rem;
    }
    @media (min-width: 500px) and (max-width: 800px) {
      margin-inline-end: 0rem !important;
    }
    @media #{$xs-layout} {
      // margin-inline-end: 0rem;
    }
    margin-inline-start: 1rem;
    text-align: start;
    color: #919191;
    font-size: 12px;
    font-weight: normal;
    width: 50%;
  }
}

.sub-landing-image {
  height: auto !important;
}

.swiper-slide {
  &.landing {
    @media #{$xs-layout} {
      width: 300px !important;
    }
  }
}

.landing-filter-row {
  justify-content: space-between;
  @media #{$xs-layout} {
    justify-content: space-around;
  }
}


.or {
  justify-content: center;
  text-align: center;
  margin-top: 11px;
  margin-bottom: -8px;
  color: $web-green-color;
  align-self: center;
}

.mobile-ticket-view {
  // background-color: red;
  height: auto;
  border-radius: 15px;
  border: 1px solid #DFE0DF;
  margin-bottom: 30px;

  .image-ticket {
    margin-top: 20px;
    margin-bottom: 20px;
    // height: 253px;
    // width: 283px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      object-fit: cover;

    }
  }

  .button-price-view {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 20px;

    .price {
      border-radius: 10px;
      border: 1px solid #DFE0DF;
      height: 40px;
      width: 300px;
      margin-inline-end: -30px;
      align-items: center;
      display: flex;
      justify-content: center;
      color: $web-green-color;

      .price-text {
        margin-inline-start: -60px;
      }

    }

    .buy {
      border-radius: 10px;
      border: 1px solid #DFE0DF;
      height: 40px;
      width: 300px;
      background-color: $web-green-color;
      align-items: center;
      display: flex;
      justify-content: center;
      color: white;
      text-decoration: none;
    }
  }
}

.terms-block {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 0 10px #0000000F;
  border: 0.10000000149011612px solid #0E1815;
  border-radius: 12px;
  padding: 28px 44px;

  .terms-block-title {
    font: normal normal bold 18px/20px Gotham;
    letter-spacing: 0px;
    color: #0E1815;
    margin-bottom: 15px;
  }

  .terms-block-desc {
    font: normal normal normal 14px/24px Gotham;
    letter-spacing: 0px;
    color: #242424;
    text-transform: lowercase;
  }

}

.terms_condition, .terms_condition:hover {
  color: #000;
  font-weight: bold;
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1320px !important;
  }
}

.show-modal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(51, 51, 51, 33%);
  z-index: 9999;

  .show-modal-body {
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    height: 400px;
    width: 400px;
    max-width: 90%;
    z-index: 9;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 13px 8px #333;
    padding: 30px;
    border-radius: 16px;
  }

  .show-modal-btn {
    background: #838383 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: #fff;
    padding: 13px 59px;
    cursor: pointer;
    text-align: center;
  }

  .show-modal-title {
    font-size: 26px;
    font-weight: bold;
    padding-top: 8px;
  }

  .show-modal-icon {
    text-align: center;

    i.fa-check-circle-o {
      font-size: 140px;
      color: #006937;
      text-align: center;
    }

    i.fa-ban {
      font-size: 140px;
      color: #e74c3c;
      text-align: center;
    }
  }
}

.payment-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #0000005e;
  z-index: 2;

  div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    color: #fff;
  }
}

.full-body {
  height: 58vh;
}