/*------- 21. Checkout page  -----------*/

.billing-info-wrap {
  h3 {
    font-size: 20px;
    color: #000;
    margin: 0 0 20px;
    font-weight: 500;
  }
  .billing-info,
  .billing-select {
    label {
      color: #000;
      margin: 0 0 7px;
    }
    input {
      background: transparent none repeat scroll 0 0;
      border: 1px solid #e6e6e6;
      color: $web-disable;
      font-size: 14px;
      padding-left: 20px;
      padding-right: 10px;
    }
    input.billing-address {
      margin-bottom: 10px;
    }
  }
  .billing-select {
    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      border: 1px solid #e6e6e6;
      color: $web-disable;
      font-size: 14px;
      height: 45px;
      padding: 2px 20px;
      //background: rgba(0, 0, 0, 0) url("../../assets/img/icon-img/select.png") no-repeat scroll right 18px center;
      cursor: pointer;
    }
  }


}

.your-order-area {
  @media #{$md-layout} {
    margin-top: 30px;
  }
  @media #{$xs-layout} {
    margin-top: 30px;
  }
  h3 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 20px;
    color: #000;
  }
  .discount-code {
    margin: 21px 0 0;
    p {
      margin: 0 0 15px;
    }
    form {
      input {
        background: #fff;
        border: 1px solid #ebebeb;
        height: 40px;
        margin-bottom: 10px;
        padding-left: 10px;
      }
      button.cart-btn-2 {
        background-color: $theme-color;
        border: medium none;
        border-radius: 50px;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
        padding: 13px 42px 12px;
        text-transform: uppercase;
        transition: all 0.3s ease 0s;
        // &:hover {
        //   background-color: $web-disable;
        // }
        &.cou{
          background-color: $web-disable !important;
          cursor: not-allowed;
        }
      }
    }
  }
  .your-order-wrap {
    padding: 38px 45px 44px;
    background: #f6f6f6;
    @media #{$lg-layout} {
      padding: 30px 20px 36px;
    }
    @media #{$xs-layout} {
      padding: 30px 20px 36px;
    }
    .your-order-product-info {
      .your-order-top {
        ul {
          display: flex;
          justify-content: space-between;
          li {
            font-size: 16px;
            font-weight: 500;
            list-style: outside none none;
          }
        }
      }
      .your-order-middle {
        border-bottom: 1px solid #dee0e4;
        border-top: 1px solid #dee0e4;
        margin: 15px 0;
        padding: 19px 0 8px;
        ul {
          li {
            display: flex;
            justify-content: space-between;
            margin: 0 0 10px;
            span {
            }
          }
        }
      }
      .your-order-bottom {
        ul {
          align-items: center;
          display: flex;
          justify-content: space-between;
          li {
            font-size: 14px;
            font-weight: 400;
            list-style: none;
          }
          li.your-order-shipping {
            font-size: 16px;
            color: #212121;
            font-weight: 400;
          }
        }
      }
      .your-order-total {
        border-bottom: 1px solid #dee0e4;
        // border-top: 1px solid #dee0e4;
        margin: 18px 0 33px;
        padding: 17px 0 19px;
        ul {
          align-items: center;
          display: flex;
          justify-content: space-between;
          li.order-total {
            font-weight: 500;
            color: #212121;
            font-size: 18px;
            padding-block-end: 10px;
          }
          li {
            font-weight: 500;
            color: $theme-color;
            font-size: 16px;
            list-style: outside none none;
            &.old {
              color: #8e8e8e;
              text-decoration: line-through;
              font-weight: unset !important;
          }
          &.discount{
            p{
              color: $web-green-color !important;
            }
          }
          }
        }
      }
    }
  }

  .diasbled{
    background-color: $web-sold !important;
  }
  .place-order > a,
  .place-order > button {
    background: none;
    border: none;
    background-color: $theme-color;
    color: #fff;
    display: block;
    width: 100%;
    // font-weight: 500;
    letter-spacing: 1px;
    line-height: 1;
    padding: 18px 20px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 50px;
    z-index: 9;
    &:hover {
      background-color: $theme-color;
    }
  }
}

.checkout-area {
  padding-top: 45px;
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

.payment {
  background-color: #CDCDCD!important;
  pointer-events: none !important;
}

.display-none{
  display: none !important;
}
