.tickets-top-slider {
  height: 300px;
  background-size: cover;
  z-index: -5;
    content: "";
    top: 0;
    left: 0;
    box-shadow: inset 0 0 0 1000px rgba(7, 151, 72, 0.2);

  .breadcrumb-box {
    h1 {

    }
    .subtitle {
      font-weight: 500 !important;
    }
    padding-top: 80px;
    .breadcrumbs {
      .breadcrumb {
        background-color: transparent;
        padding-left: 0px !important;
      }
      a, span {
        font-size: 20px;
        color: $web-yellow-color;
      }
    }
    .breadcrumb-item + .breadcrumb-item::before {
      color: $web-yellow-color;
    }
  }
}

.tickets-section-one {
  .tickets-section-one-inner {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}

.tickets-section-two {
  .tickets-section-two-inner {
    padding-top: 0px;
    padding-bottom: 50px;

    .ticket-box {
      height: auto;
      border: 5px solid #eee;
      margin-bottom: 5px;
      .img-tick{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 200px;
        object-fit: cover;
        // margin-block-start: 5px;
      }

      .img-tick-2{
        // display: block;
        margin-right: auto;
        width: 30%;
        height: 100%;
        // margin-block-start: 5px;
      }

      .ticket-content {
        padding: 15px;
      }
      .ticket-text-space {
        height: 45px;
      }
      .decr{
        // position: relative;
        // bottom: 10px;
        line-height: 1.5;
      }
      .ticket-content-2 {
        position: relative;
        left: 80%;
        padding: 15px;
      }
    }
  }
}

.margin-button-tick{
 
  margin-inline-start: 60%;
  position: relative;
    bottom: 20px;
  @media #{$xs-layout} {
    margin-inline-start: 60%;
    margin-block-end: 5px;
  }
  @media #{$lg-layout} {
    position: relative;
    bottom: 20px;
  }
  @media #{$xl-layout} {
    position: relative;
    bottom: 20px;
  }
}

.margin-inline-end-20{
  margin-inline-end: 5px;
}

.margin-inline-end-10{
  margin-inline-end: 1%;
}

.mxh-100{
  max-height: 180px;
  object-fit: cover;
}

.mmh-100{
  min-height: 150px;
}

.allign-center{
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}


.m-16{
  margin-left: -15px;
  margin-right: -15px;
}

.pading-0{
  padding-left: 0px;
  padding-right: 0px;
}
.object-fit-cover{
  object-fit: cover;
}
.margin-20{
 margin-block-end: 20px;
 margin-block-start: 20px;
 margin-inline-start: 20px;
 margin-inline-end: 20px;
}
.margin-10{
  margin-block-end: 5px;
  margin-block-start: 5px;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
}
.transtiton{
  transition: 0.5s;

}

.dir-lrt{
  direction: ltr !important;
}

.otp{
  

.card {
    width: 600px;
    border: none;
    height: 500px;
    @media #{$xs-layout} {
      width: 100%;
      height: 300px;
    }

    box-shadow: 0px 5px 20px 0px #d2dae3;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center
}

.card 

.inputs input {
    width: 40px;
    height: 40px
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0
}

.card-2 {
    background-color: #fff;
    padding: 10px;
    width: 350px;
    height: 100px;
    bottom: -50px;
    left: 20px;
    position: absolute;
    border-radius: 5px
}

.card-2 .content {
    margin-top: 50px
}

#partitioned {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 305px;
  min-width: 305px;
  background-color: white;
}

#divInner{
  left: 0;
  position: sticky;
}

#divOuter{
  width: 190px; 
  overflow: hidden;
}


.form-control:focus {
    box-shadow: none;
    border: 2px solid $web-green-color
}

.validate {
    border-radius: 20px;
    height: 40px;
    background-color: $web-green-color;
    border: 1px solid $web-green-color;
    width: 140px

}
.margin-inline{
  margin-inline-end: 20px;
}

}

.diasbled-btn{
  background-color:white !important;
  border: 1px solid $web-disable !important;
  // color: white !important;
}

.he-100 {
  height: 60vh !important
}
.apple-pay-button {
  // display: inline-block;
  margin-block-start: 20px;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 5px;
  padding: 22px 20px;
  border-radius: 50px;

}

.img-row{

  .type-badge{
      width: auto;
      padding: 19px !important;
      height: 35px;
      background-image: linear-gradient(0deg, rgba(210, 158, 14, 0.65), rgba(210, 158, 14, 0.65)), url(https://i.ibb.co/c2Vc3Mb/shutterstock-1766512784.jpg);      position: absolute;
      z-index: 7;
      color: white;
      margin-inline-start: 10px;
      margin-block-start: 10px;
      text-align: center;
      vertical-align: middle;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.3;
      transition: 0.3s;
      
    }
    &:hover .type-badge{
      opacity: 1;
    }
    .type-badge-sold{
      width: auto;
      padding: 19px !important;
      height: 35px;
      background-image: linear-gradient(0deg, rgba(210, 158, 14, 0.65), rgba(210, 158, 14, 0.65)), url(https://i.ibb.co/c2Vc3Mb/shutterstock-1766512784.jpg);      position: absolute;
      z-index: 7;
      color: white;
      margin-inline-start: 10px;
      margin-block-start: 10px;
      text-align: center;
      vertical-align: middle;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.3;
    }
   
}

.mx-h-200{
  max-height: 270px;
  justify-content: space-around;
  margin-top: 20px !important;
  @media screen and (min-width: 800px) {
    max-height: 285px;
  }
}

.w-80{
  width: 80% !important;
  @media (min-width: 500px) and (max-width: 800px) {
    width: 70% !important;

  }
  img{
    max-height: 180px !important;
    border-radius: 15px;
  }
}

.card-block{
  margin-inline-start: -10rem;
  @media screen and (min-width: 1900px) {
    margin-inline-start: -15rem;
  }
}

.card-package{
  &.clicked{
    border: 1px solid #101815 !important;

  }
  border-radius: 15px !important;
}
.package-info{
  justify-content: center !important;
  align-items: center !important;
  margin-block-start: 10px;
  margin-block-end: 10px;
  margin-bottom: 20px;
  
  .info-contain{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self:center;
    justify-self: center;
    width: 90%;
    flex-direction: row;
    border: 1px solid #E6E7E7 !important;
    &.clicked{
      border: 1px solid #101815 !important;
    }
    border-radius: 15px;
    svg{
      margin-inline-start: 10px;
      margin-block-start: 10px;
      margin-block-end: 10px;
      margin-inline-end: 5px;
    }
    p1{
      font: normal normal  15px Gotham;
      font-weight: 600;
      letter-spacing: 0px;
      color: #0E1815;
    }
  }
  .package-price{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    position: absolute;
    // left: 0 !important;
    &.en{

      right: 0;
    }
    &.ar{
      left: 0 !important;
    }
    margin-inline-end: 10rem;
    .price{

    }
    .buybutton{
      position: absolute;
      right: 0;
      background: #0E1815;
      margin-inline-end: -8rem;
      width: 6rem;
      &.clicked{
        height: 2.4rem;
      }
      &.ar {
        transform: rotate(180deg);
        right: 89px;
        p1{
          transform: rotate(180deg);
        }

      }
      height: 2.1rem;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    p1{
      color: white;
      font-size: 13px;
      
    }
    }
  }

}

.pro-details-cart{
  margin: 0 25px 0 10px;
  @media #{$xs-layout}{
      margin: 0 10px 0 10px;
  }
  @media #{$md-layout}{
      margin: 0 17px 0 10px;
  }
  a, button {
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      background: none;
      border: none;
      background-color: #343538;
      &.sold{
          border: none !important ;
          background-color: $web-sold !important;
          pointer-events: none !important;
          color: white !important;
        }
      display: inline-block;
      line-height: 1;
      padding: 23px 38px 23px;
      @media #{$xs-layout}{
          padding: 23px 12px 23px;
      }
      @media #{$md-layout}{
          padding: 22px 22px 22px;
      }
      z-index: 99;
      &:hover {
          border: none;
      }

      &:disabled{
          cursor: not-allowed;
          &:before, &:after{
              display: none;
          }
      }
  }
}
.product-details-content {

  .button-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media #{$lg-layout}{
      &.ml-70{
          margin-left: 0;
      }
  }
  @media #{$md-layout}{
      &.ml-70{
          margin-left: 0;
      }
  }
  @media #{$xs-layout}{
      &.ml-70{
          margin-left: 0;
          margin-top: 30px;
      }
  }
  &.quickview-content {
      @media #{$xs-layout} {
          margin-top: 30px;
      }
  }
  h2{
      color: #010101;
      font-size: 24px;
      margin: 0 0 0px;
      line-height: 1;
  }
  .product-details-price {
      display: flex;
      align-items: center;
      margin: 15px 0 26px;
      span {
          font-size: 24px;
          color: $web-yellow-color;
          &.old {
              color: $web-disable;
              font-size: 18px;
              text-decoration: line-through;
              margin-left: 20px;
          }
      }
  }
  .pro-details-rating-wrap {
      display: flex;
      align-items: center;
      margin: 0 0 17px;
      .pro-details-rating {
          position: relative;
          margin-right: 39px;
          &:before {
              position: absolute;
              right: -19px;
              top: 4px;
              content: "";
              height: 15px;
              width: 2px;
              background-color: #d1d1d1;
              display: none;
          }
          i{
              color: #5f5d5d;
              font-size: 17px;
              margin-right: 5px;
              &.yellow {
                  color: #ffa900;
              }
          }
      }
      span {
          a {
             font-size: 15px;
              color: $theme-color;
              &:hover {
                  color: #000;
              }
          }
      }
  }
  p{
      font-size: 15px;
      line-height: 28px;
      color: $web-disable;
      margin: 0;
  }
  .pro-details-list{
      margin: 20px 0 34px;
      border-bottom: 1px solid #e5e5e5;
      padding: 0 0 37px;
      p{
          margin-block-end: 2em;
          font-size: 20px;
      }
      p2{
         
          color: #BDA961;
          font-size: 15px;
      }
      ul {
          li{
              color: $web-disable;
              margin: 0 0 5px;
              &:last-child {
                  margin: 0;
              }
          }
      }
  }
  .pro-details-size-color{
      display: flex;
      @media #{$xs-layout}{
          display: block;
      }
      @media #{$sm-layout}{
          display: flex;
      }
      .pro-details-color-wrap {
          margin-right: 20px;
          @media #{$xs-layout}{
              margin-bottom: 20px;
          }
          @media #{$sm-layout}{
              margin-bottom: 0px;
          }
          span {
              display: block;
              font-weight: 500;
              margin: 0 0 15px;
              font-size: 15px;
          }
          .pro-details-color-content{
              ul {
                  li {
                      border-radius: 50px;
                      cursor: pointer;
                      display: block;
                      float: left;
                      height: 14px;
                      margin-right: 15px;
                      position: relative;
                      transition: all 0.4s ease 0s;
                      width: 14px;
                      &:last-child {
                          margin-right: 0px;
                      }
                      &.blue {
                          background-color: #4798f3;
                          border: 1px solid #4798f3;
                      }
                      &.maroon {
                          background-color: #736751;
                          border: 1px solid #736751;
                      }
                      &.gray {
                          background-color: #c0c0c0;
                          border: 1px solid #c0c0c0;
                      }
                      &.green {
                          background-color: $web-green-color;
                          border: 1px solid $web-green-color;
                      }
                      &.yellow {
                          background-color: #e28b37;
                          border: 1px solid #e28b37;
                      }
                  }
              }
              &--single{
                  position: relative;
                  border-radius: 50px;
                  cursor: pointer;
                  display: inline-block;
                  height: 14px;
                  width: 14px;
                  margin-right: 15px;
                  transition: all 0.4s ease 0s;
                  &:last-child {
                      margin-right: 0;
                  }
                  input{
                      position: absolute;
                      opacity: 0;
                      cursor: pointer;
                      height: 100%;

                      &:checked{
                          & ~ .checkmark{
                              &:after{
                                  visibility: visible;
                                  opacity: 1;
                              }
                          }
                      }
                  }
                  &.blue {
                      background-color: #4798f3;
                      border: 1px solid #4798f3;
                  }
                  &.maroon {
                      background-color: #736751;
                      border: 1px solid #736751;
                  }
                  &.gray {
                      background-color: #c0c0c0;
                      border: 1px solid #c0c0c0;
                  }
                  &.green {
                      background-color: $web-green-color;
                      border: 1px solid $web-green-color;
                  }
                  &.yellow {
                      background-color: #e28b37;
                      border: 1px solid #e28b37;
                  }
                  &.white {
                      background-color: #fff;
                      border: 1px solid $web-disable;
                  }
                  &.black {
                      background-color: $web-disable;
                      border: 1px solid $web-disable;
                  }
                  &.brown {
                      background-color: brown;
                      border: 1px solid brown;
                  }
                  .checkmark {
                      margin: 0;
                      height: 14px;
                      width: 14px;
                      position: relative;
                      display: inline-block;
                      &:after{
                          position: absolute;
                          top: -7px;
                          left: -4px;
                          width: 20px;
                          height: 20px;
                          border: 2px solid $web-disable;
                          border-radius: 50%;
                          content: '';
                          visibility: hidden;
                          opacity: 0;
                          transition: 0.3s;
                      }
                  }

                  &:hover{
                      input{
                          
                          & ~ .checkmark{
                              &:after{
                                  visibility: visible;
                                  opacity: 1;
                              }
                          }
                          
                      }
                  }
              }
          }
      }
      .pro-details-size {
          span {
              display: block;
              font-weight: 500;
              margin: 0 0 10px;
              font-size: 15px;
          }
          .pro-details-size-content {
              ul {
                  li{
                      display: inline-block;
                      list-style: outside none none;
                      margin: 0 2px 0 0;
                      a {
                          background-color: #f1f2f6;
                          color: #000;
                          display: inline-block;
                          font-size: 12px;
                          line-height: 1;
                          padding: 6px 9px 7px;
                          text-transform: uppercase;
                          &:hover {
                              background-color: $theme-color;
                              color: #fff;
                          }
                      }
                  }
              }
              &--single{
                  margin: 0 5px 0 0;
                  background-color: #f1f2f6;
                  color: #000;
                  display: inline-block;
                  font-size: 12px;
                  line-height: 1;
                  text-transform: uppercase;
                  position: relative;
                  &:hover {
                      background-color: $theme-color;
                      color: #fff;
                  }
                  input{
                      position: absolute;
                      opacity: 0;
                      top: 0;
                      left: 0;
                      cursor: pointer;
                      height: 100%;

                      &:checked{
                          & ~ .size-name{
                              background-color: $theme-color;
                              color: $white;
                          }
                      }
                  }
                  .size-name{
                      margin-bottom: 0;
                      width: 100%;
                      padding: 8px;
                      font-size: 12px;
                      font-weight: 400;
                  }
              }
          }
      }
  }
  .pro-details-quality {
      display: flex;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 34px;
      .cart-plus-minus {
          border: 1px solid #e8e8e8;
          display: inline-block;
          height: 60px;
          overflow: hidden;
          padding: 0;
          position: relative;
          width: 80px;
          .qtybutton {
              color: #8f8f8f;
              cursor: pointer;
              float: inherit;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              margin: 0;
              position: absolute;
              text-align: center;
              transition: all 0.3s ease 0s;
              width: 24px;
              background: none;
              border: none;
          }
          .dec.qtybutton {
              height: 60px;
              left: 0;
              top: 0;
          }
          .inc.qtybutton {
              height: 60px;
              right: 0;
              top: 0;
          }
          input.cart-plus-minus-box {
              background: transparent none repeat scroll 0 0;
              border: medium none;
              color: #8f8f8f;
              float: left;
              font-size: 14px;
              height: 60px;
              margin: 0;
              padding: 0;
              text-align: center;
              width: 80px;
          }
      }
      .pro-details-cart{
          margin: 0 25px 0 10px;
          @media #{$xs-layout}{
              margin: 0 10px 0 10px;
          }
          @media #{$md-layout}{
              margin: 0 17px 0 10px;
          }
          a, button {
              color: #fff;
              font-weight: bold;
              text-transform: uppercase;
              background: none;
              border: none;
              background-color: #343538;
              &.sold{
                  border: none !important ;
                  background-color: $web-sold !important;
                  pointer-events: none !important;
                  color: white !important;
                }
              display: inline-block;
              line-height: 1;
              padding: 23px 38px 23px;
              @media #{$xs-layout}{
                  padding: 23px 12px 23px;
              }
              @media #{$md-layout}{
                  padding: 22px 22px 22px;
              }
              z-index: 99;
              &:hover {
                  border: none;
              }

              &:disabled{
                  cursor: not-allowed;
                  &:before, &:after{
                      display: none;
                  }
              }
          }
      }
      .pro-details-wishlist {
          a, button {
              font-size: 18px;
              color: #000;
              background: none;
              border: none;
              &:hover, &.active {
                  color: $theme-color;
              }
          }
      }
      .pro-details-compare {
          a, button{
              font-size: 18px;
              color: #000;
              margin-left: 25px;
              background: none;
              border: none;
              @media #{$xs-layout}{
                  margin-left: 10px;
              }
              &:hover, &.active {
                  color: $theme-color;
              }
          }
      }
  }
  .pro-details-meta {
      display: flex;
      margin: 0 0 10px;
     
      span {
          font-size: 15px;
          color: #676767;
          margin-inline-end: 5px;
      }
      ul{
        padding: 7px;
        box-shadow: 0 0 3px #9ca3af;
        bottom: 10px;
        position: relative;
          li+li:before {
              content: ", ";
          }
          li{
              display: inline-block;
              margin: 0 10px 0 0;
              
              a{
                  color: #676767;
                  font-size: 15px;
                  @media #{$xs-layout}{
                      font-size: 14px;
                  }
                  &:hover {
                      color: $theme-color;
                  }
              }
          }
      }
  }
  .pro-details-social {
      margin: 24px 0 0;
      ul {
          display: flex;
          li{
              margin-right: 40px;
              &:last-child {
                  margin-right: 0px;
              }
              a{
                  font-size: 16px;
                  color: #343538;
                  &:hover {
                      color: $theme-color;
                  }
              }
          }
      }
  }
  &.pro-details-slider-content {
      @media #{$xs-layout}{
          &.mt-50 {
              margin-top: 30px;
          }
      }
      .product-details-price {
          justify-content: center;
      }
      p {
          width: 59%;
          margin: 0 auto;
          @media #{$lg-layout}{
              width: 80%;
          }
          @media #{$md-layout}{
              width: 100%;
          }
          @media #{$xs-layout}{
              width: 100%;
          }
      }
      .pro-details-rating-wrap {
          justify-content: center;
      }
      .pro-details-size-color {
          justify-content: center;
          text-align: left;
          @media #{$xs-layout}{
              text-align: center;
              & .pro-details-color-content {
                  justify-content: center;
                  display: flex;
              }
          }
      }
      .pro-details-quality {
          justify-content: center;
      }
      .pro-details-meta {
          justify-content: center;
      }
      .pro-details-social {
          ul {
              justify-content: center;
          }
      }
  }
  .tacbox {
    display:block;
    padding: 1em;
    margin: 2em;
    border: 3px solid #ddd;
    background-color: #eee;
    max-width: 800px;
  }
  
  input {
    height: 1em;
    width: 1em;
    vertical-align: middle;
    margin-inline-end: 10px;
    &.temcolor{
      outline: 1px solid red
    }
  }
  .temcolor{
    color: red !important;
  }
}

.product-large-image-wrapper{
  img{

    border-radius: 15px;
  }
}
.modal-content{
  max-width: 100% !important;
  opacity: 1 !important;
  border-radius: 14px !important;

}
.modal-header{
  border: none !important;
  &.ar{
  .close{
      position: absolute;
      left: 0;
  }
   
  }
}

.modal-share{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}

.remaining-style{
  margin-block-start: -1rem;
   margin-block-end: 1rem;
   display: flex;
   flex-direction: row;
   .label{
     color: red;
     margin-inline-end: 3px;
   }


}