/*----- 3. Slider style  ------*/



.swiper-slide .slider-content * {
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.swiper-slide-active {
    .slider-animated-1 {
        h3 {
            -webkit-animation-delay: 1.0s;
            animation-delay: 1.0s;
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
        }
        h1 {
            -webkit-animation-delay: 1.3s;
            animation-delay: 1.3s;
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
        }
        h5 {
            -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
        }
        p {
            -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
        }
        a {
            -webkit-animation-delay: 1.6s;
            animation-delay: 1.6s;
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
        }

    }

}

.nav-style-2.owl-carousel > .owl-nav div, .nav-style-2 .ht-swiper-button-nav {
    color: #000;
    display: inline-block;
    font-size: 60px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease 0s;
    width: 65px;
    height: 84px;
    line-height: 94px;
    padding: 0;
    background: none;
    border: none;
    background-color: #fff;
    @media #{$lg-layout}{
        width: 45px;
        height: 64px;
        line-height: 74px;
        font-size: 45px;
    }
    @media #{$md-layout}{
        width: 45px;
        height: 64px;
        line-height: 74px;
        font-size: 45px;
    }
    @media #{$xs-layout}{
        width: 35px;
        height: 50px;
        line-height: 54px;
        font-size: 30px;
    }
    &:hover {
        color: #fff;
        background-color: $theme-color;
    }
    &.owl-next {
        left: auto;
        right: 0px;
    }

    &.swiper-button-prev{
        left: 0;
    }
    &.swiper-button-next{
        right: 0;
    }
}

.nav-style-1 {
    
    .ht-swiper-button-nav {
        width: 80px;
        height: 80px;
        padding: 0;
        color: #fff;
        display: inline-block;
        font-size: 80px;
        left: 80px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease 0s;
        background: none;
        border: none;
        @media #{$xl-layout}{
            left: 30px;
        }
        @media #{$lg-layout}{
            left: 30px;
        }
        @media #{$md-layout}{
            left: 15px;
            font-size: 60px;
        }
        @media #{$xs-layout}{
            left: 15px;
            font-size: 50px;
        }
        &:hover {
            color: $web-yellow-bg;
        }
        &.swiper-button-next {
            &.en{

                left: auto;
                right: 80px;
            }
            &.ar{
                right: auto;
                left: 80px;
            }
            @media #{$xl-layout}{
              &.en{
                right: 30px;
                }
             &.ar{
                left: 30px;
                }
            }
            @media #{$lg-layout}{
                &.en{
                    right: 30px;
                    }
                 &.ar{
                    left: 30px;
                    }
                
            }
            @media #{$md-layout}{
                &.en{
                    right: 15px;
                    }
                 &.ar{
                    left: 15px;
                    }
                
            }
            @media #{$xs-layout}{
                &.en{
                    right: 15px;
                    }
                 &.ar{
                    left: 15px;
                    }
            }
        }
    }

&.nav-testi-style {
    .owl-nav {
        div { 
            font-size: 50px;
            left: -155px;
            color: #8d8c8c;
            @media #{$xl-layout}{
                left: -80px;
            } 
            @media #{$lg-layout}{
                left: -80px;
            } 
            @media #{$md-layout}{
                left: -30px;
            } 
            @media #{$xs-layout}{
                display: none;
            } 
            &:hover {
                color: #3d6882;
            }
            &.owl-next {
                right: -155px;
                left: auto;
                @media #{$xl-layout}{
                    right: -80px;
                } 
                @media #{$lg-layout}{
                    right: -80px;
                } 
                @media #{$md-layout}{
                    right: -30px;
                } 
            }
        }
    }
    &.nav-testi-mrg {
        .owl-nav {
            div { 
                left: -80px;
                color: #fff;
                @media #{$md-layout}{
                    left: 0px;
                } 
                @media #{$xs-layout}{
                    display: none
                } 
                &.owl-next {
                    right: -80px;
                    left: auto;
                    @media #{$md-layout}{
                        right: 0px;
                        left: auto;
                    }
                    @media #{$xs-layout}{
                        display: none
                    }
                }
            }
        }
    }
}
}

.slider-area {
    &:hover .nav-style-1 .ht-swiper-button-nav {
        opacity: 1;
        visibility: visible;
    }
    &.ml-10{
        margin-left: 0;
    }
    @media #{$xl-layout}{
        &.ml-70 {
            margin-left: 15px;
        }
        &.mr-70 {
            margin-right: 15px;
        }
    }
    @media #{$lg-layout}{
        &.ml-70 {
            margin-left: 30px;
        }
        &.mr-70 {
            margin-right: 30px;
        }
    }
    @media #{$md-layout}{
        &.ml-70 {
            margin-left: 40px;
        }
        &.mr-70 {
            margin-right: 40px;
        }
    }
    @media #{$xs-layout}{
        &.ml-70 {
            margin-left: 15px;
        }
        &.mr-70 {
            margin-right: 15px;
        }
    }

    
.swiper-pagination-bullets {
    bottom: 26px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    .swiper-pagination-bullet {
        background: #fff none repeat scroll 0 0;
        opacity: 1;
        border-radius: 100%;
        display: inline-block;
        height: 14px;
        margin: 0 8px;
        width: 14px;
        position: relative;
        &:before {
            position: absolute;
            content: "";
            left: -3px;
            right: -3px;
            top: -3px;
            bottom: -3px;
            border: 1px solid transparent;
            border-radius: 100%;
        }
        &-active:before {
            border: 1px solid #fff;
        }
    }
}

}


/* slider 5 */

.slider-height-4 {
    height: 950px;
    margin-top: 100px;
    @media (min-width: 1200px) {
        height: 780px;
    }

    @media #{$xx-layout}{
        height: 600px;
    }
    @media #{$xl-layout}{
        height: 900px;
    }
    @media #{$lg-layout}{
        height: 850px;
    }
    @media #{$md-layout}{
        height: 830px;
    }
    @media #{$xs-layout}{
        height: 520px;
        
    }
}
.slider-content-5 {
    padding-top: 120px;
    padding-inline-start: 100px;
    @media #{$xs-layout}{
        padding-inline-start: 0px;
        
    }
    .title{
        // font-weight: 500;
        font-size: 40px;
        margin: 0;
        color: #fff;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-shadow: 0px 0px 4px #00000066;
        text-transform: capitalize;
        opacity: 1;
    }
    .sub_title{
        font-size: 16px;
        margin: 0;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-shadow: 0px 0px 4px #00000066;
        text-transform: capitalize;
        opacity: 1;
    }
    .description{
        font-size: 16px !important;
        font-weight: normal !important;
    }
    h3{
        font-weight: 500;
        letter-spacing: .5px;
        font-size: 30px;
        margin: 0;
        color: #fff;
        line-height: 40px;
    }
    h1{
        font-weight: 500;
        font-size: 72px;
        margin: 27px 0 22px;
        letter-spacing: .5px;
        color: #fff;
        line-height: 56px;
        @media #{$md-layout}{
            font-size: 58px;
            margin: 17px 0 20px;
        }
        @media #{$xs-layout}{
            font-size: 45px;
            margin: 15px 0 15px;
            line-height: 38px;
        }
    }
    p{
        // font-size: 18px;
        margin: 0 0 45px;
        font-weight: normal;
        // margin-bottom: 10px;
        margin-top: 20px;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-shadow: 0px 0px 4px #00000066;
        text-transform: capitalize;
        opacity: 1;
    }
    p span{
        
     }
}
.slider-btn-5 {
    a{
        font-size: 16px;
        color: #010101;
        background-color: #fff;
        display: inline-block;
        line-height: 1;
        padding: 17px 40px 18px; 
        transition: all .3s ease-in-out 0s;
        &:hover {
            border: none;
            color: #fff;
        }
    }
}

.slider-area {
    &:hover .nav-style-2.owl-carousel > .owl-nav div, &:hover .nav-style-2 .ht-swiper-button-nav {
        opacity: 1;
        visibility: visible;
    }
    &.ml-10{
        margin-left: 0;
    }
}

.top{
    position: relative!important;
    bottom: 90px !important;
    
}

.top-sm{
    @media only screen and (max-width: 500px) {
    position: relative!important;
    top: 70px !important;
    left:30px
    }
}



.ht-swiper-button-nav{
    &:after{
        font-size: 0;
    }
}

.slider-search-box {
    box-shadow: 0px 0px 8px #0000001a;
    opacity: 1;
    border-radius: 15px;
    bottom: 3rem;
    margin: auto;
    display: flex;
    position: relative;
    width: 70%;
    height: 6rem;
    background-color: $web-green-color;
    z-index: 100;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    @media #{$xs-layout} {
        height: 4rem;
        bottom: 2.2rem;
    }
    .search-box {
        background-color: white;
        width: 85%;
        @media #{$xs-layout}{
            width: 75%;

        }
        height: 50%;
        align-self: center;
        justify-self: center;
        border-radius: 10px;
        box-shadow: 0px 0px 8px #0000001a;
        opacity: 1;
        text-align: start;
        display: flex;
        justify-content: start;
        align-items: center;
        
        p{
            margin-inline-start: 1rem;
            color: "#336656";
        }
    }
    .search-box::placeholder {
        color: #d8d8d8 !important;
      }
    .search-icon{
        cursor:  pointer !important;
        align-self: center;
        justify-self: center;
        text-align: start;
        display: flex;
        justify-content: start;
        align-items: center;
        background: #FFFFFF12 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 0px 18px #00000030;
        border: 1px solid #FFFFFF;
        border-radius: 10px;
        opacity: 1;
        width: 5%;
        height: 50%;
        justify-content: center;
        @media #{$xs-layout}{
            border: 0px solid #FFFFFF;

        }
    }
}



