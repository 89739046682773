/*------- 5. Section title style  -------*/

.section-title {
  @media #{$xs-layout} {
    &.mb-50 {
      margin-bottom: 30px;
    }
  }
  &.white{
    .side-lines{
      color: #ffff !important;
      &::before {
          background: #ffff 0% 0% no-repeat padding-box;
      }
      &::after {
        background: #ffff 0% 0% no-repeat padding-box;
      }
    }
    h2 {
      
      &::before {
        background-color: #fff;
      }
  
      &::after {
        background-color: #fff;
      }
    }
  }

  h2 {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    display: inline-block;
    @media #{$xs-layout} {
      font-size: 24px;
      line-height: 30px;
      &.mb-50 {
        margin-bottom: 30px;
      }
    }

    &::before {
      position: absolute;
      content: "";
      left: -170px;
      background-color: $theme-color;
      width: 147px;
      height: 2px;
      top: 17px;
      @media #{$xs-layout} {
        left: -45px;
        width: 30px;
      }
    }

    &::after {
      position: absolute;
      content: "";
      right: -170px;
      background-color: $theme-color;
      width: 147px;
      height: 2px;
      top: 17px;
      @media #{$xs-layout} {
        right: -45px;
        width: 30px;
      }
    }
  }
  &.mb-55 {
    @media #{$xs-layout} {
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
  }
  a {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
  }

  &.no-border {
    h2 {
      font-size: 32px;
      &:before,
      &:after {
        display: none;
      }
    }
  }
}
.mirror {
  transform: scale(-1, 1);
}
.left-90p {
  left: 88%;
}
.left-5p {
  left: 6%;
  bottom: 5%;
}
.text-body-3 {
  font-size: 19px !important;
  color: #363b40;
  // top: 10px;
  position: relative;
  font-weight: normal;

  &:hover {
    color: #757474;
    text-decoration: none;
  }
  @media #{$xxs-layout} {
    display: none;
  }
}

.pl-reight {
  padding-left: 70% !important;
}
.section-title-2 {
  .text-body-2 {
    font-size: 20px;
    color: #363b40;
    bottom: 23px;
    position: relative;
    text-decoration: none;
    &:hover {
      color: #757474;
    }
    @media #{$xxs-layout} {
      display: none;
    }
  }
  h2 {
    font-size: 30px;
    color: #000000;
    font-weight: 600;
    margin: 0 0 15px;
    padding: 0 0 15px;
    position: relative;
    display: inline-block;
    top: 40px;
    right: 43%;
    @media #{$xs-layout} {
      right: 18%;
    }
    @media #{$xl-layout} {
      right: 37%;
    }
    &:before {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
      width: 80px;
      height: 2px;
      // background-color: #000000;
    }
  }
  p {
    color: $web-disable;
    margin: 0;
  }
  &.mb-60 {
    @media #{$xs-layout} {
      margin-bottom: 30px;
    }
  }
}
#outer {
  text-align: center;
  position: relative;
  overflow: hidden;
  // min-height: 60vh;
  min-height: 455px !important;
}
#home-top-video:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: transparent linear-gradient(180deg, #00000000 0%, #03231440 30%, #054426 100%) 0% 0% no-repeat padding-box;

}

#home-top-video {
  left: 0%;
  top: 0%;
  // height: 60vh;
  height: 455px;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: -1;
}

#home-text {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 1;
}
.section-title-3 {
  h4 {
    font-weight: 500;
    color: #000000;
    font-size: 24px;
  }
  &.mb-40 {
    @media #{$xs-layout} {
      margin-bottom: 20px;
    }
  }
}
.text-vidoe {
  position: absolute;
  left: 50%;
  @media (min-width: 1502px) {
    top: 20%;
  }
  top: 30%;
  transform: translate(-50%, -50%);
}

.vidoe-layer {
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.video-container-2 {
  width: 100vw;
  height: 60vh;
  background: linear-gradient(
    to right,
    rgba(37, 63, 40, 0.7),
    rgba(37, 63, 40, 0.7)
  );
  z-index: 1;
}

.iframee {
  position: absolute;
  top: 60%;
  left: 50%;

  transform: translate(-50%, -50%);
}

@media (min-aspect-ratio: 16/9) {
  .video-container iframe {
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-container iframe {
    width: 177.78vh;
  }
}

.section-border {
  margin-right: 94px;
  overflow: hidden;
  position: relative;
  @media #{$xs-layout} {
    margin-right: 0px;
  }
  &::before {
    background-color: #d2d2d2;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 11px;
    transition: all 0.4s ease 0s;
    width: 100%;
    z-index: 1;
    @media #{$xs-layout} {
      display: none;
    }
  }
}

.section-title-4 {
  position: relative;
  z-index: 999;
  h3 {
    font-weight: 600;
    color: #242424;
    font-size: 20px;
    letter-spacing: -0.25px;
    margin: 0;
    line-height: 1;
    padding-right: 45px;
    @media #{$xs-layout} {
      padding-right: 0px;
    }
  }
}

.section-title-5 {
  h2 {
    color: #010101;
    font-size: 48px;
    margin: 0;
    line-height: 38px;
    @media #{$md-layout} {
      font-size: 40px;
    }
    @media #{$xs-layout} {
      font-size: 30px;
      line-height: 27px;
    }
  }
  @media #{$xs-layout} {
    &.mb-60 {
      margin-bottom: 30px;
    }
  }
}
.text-decoratio-none {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.news-section-title {
  h2 {
    font-size: 32px;
    // color: #000000;
    font-weight: 600;
    margin: 0 0 15px;
    //padding: 0 0 15px;
    //position: relative;
    //display: inline-block;
    //top: 40px;
    right: 40%;
    @media #{$xs-layout} {
      right: 18%;
    }
    @media #{$xl-layout} {
      right: 37%;
    }
    &:before {
      //position: absolute;
      //content: "";
      //left: 0;
      //right: 0;
      //margin: 0 auto;
      //bottom: 0;
      //width: 80px;
      //height: 2px;
      // background-color: #000000;
    }
  }
  &.mb-60 {
    @media #{$xs-layout} {
      margin-bottom: 30px;
    }
  }
}
